@import "styles/variables.scss";

.button {
  background: $color-black-1;
  padding: 10px 20px;
  color: $color-white-0;
  @include font-style(1.8rem, 500, 140%);
  @include font-family-proxima();
  @include d-flex(center, center);
  gap: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  img {
    transition: all 0.4s ease;
  }

  &:hover {
    img {
      transform: translateX(5px);
      transition: all 0.4s ease;
    }
  }
}

.btn {
  background-color: $color-white-0;
  color: $color-black-1;
}

.btnWhite {
  background-color: $color-white-0;
  color: $color-black-1;
  border: 1px solid $color-black-1;
  white-space: nowrap;
  width: max-content;
}

.jamunButton {
  background-color: $color-black-1;
  color: $color-white-5;
}
