@import "styles/variables.scss";

.submit {
  @include d-flex(center, center);
  gap: 8px;
}

.spinnerWhite {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #ffffff 94%, #0000) top/3.8px 3.8px no-repeat,
    conic-gradient(#0000 30%, #ffffff);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 3.8px),
    #000 0
  );
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

.contactUsFormBox .formContent .form button {
  height: 44px;
  border-radius: 0px;
  border-top: 1px solid $color-grey-3 !important;
  border-left: 1px solid $color-grey-3 !important;
  border-bottom: 1px solid $color-grey-3 !important;
}

.react-international-phone-input-container .react-international-phone-input {
  width: 100% !important;
  border-radius: var(--react-international-phone-border-radius, 0px) !important;
  height: 44px !important;
}

.react-international-phone-flag-emoji {
  margin: 8px !important;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  display: none;
}

.react-international-phone-country-selector-button__button-content {
  width: 63px;
}
.react-international-phone-country-selector-button__button-content::after {
  content: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/contactUs/arrow.svg");
}

.react-international-phone-country-selector {
  top: 0;
  bottom: 0;
}

.react-international-phone-country-selector-dropdown {
  padding: 10px !important;
  border: 1px solid $color-grey-3 !important;
  font-family: "proxima-nova", sans-serif !important;
  @include font-style($fs: 1.6rem !important, $fw: 400, $lh: 140%);
  box-shadow: none !important;
}

.react-international-phone-input[type="tel"] {
  color: $color-grey-0;
  @include font-family-proxima();
  @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
  height: 44px;
}

.react-international-phone-input {
  font-family: "proxima-nova", sans-serif !important;
  @include font-style($fs: 1.6rem !important, $fw: 400, $lh: 140%);
}

.contactUs {
  &Container {
    @include d-flex($jc: start, $al: stretch);
    padding: 0;
    max-width: 1440px;
    margin: 0 auto;
  }

  &GetInTouch {
    flex-basis: 50%;
    padding: 60px 120px 112px 120px;

    .touchContent {
      max-width: 480px;

      .getInTouchHeading {
        margin-bottom: 113px;
        align-items: start;

        p {
          text-align: start;
        }
      }
    }
  }

  &FormBox {
    flex-basis: 50%;
    padding: 60px 120px 60px 120px;
    background-color: $color-grey-2;

    .formContent {
      background: $color-white-0;
      padding: 40px;
      max-width: 480px;
      height: 729px;

      .successMessage {
        @include d-flex($jc: center, $al: center);
        padding: 15px;
        height: 100%;
        width: 100%;

        &Content {
          @include d-flex($jc: center, $al: start);
          flex-direction: column;

          .lottie {
            margin: 0 auto;
          }

          .messageHeading {
            text-align: center;
            margin-bottom: 24px;

            h2 {
              @include font-style($fs: 3.2rem, $fw: 500, $lh: 140%);
            }
          }

          .gobackBox {
            cursor: pointer;
            width: 100%;

            span {
              display: block;
              width: fit-content;
              margin: 0 auto;
              color: $color-black-1;
              @include font-family-proxima();
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
              border-bottom: 1px solid $color-black-1;
            }
          }
        }
      }

      .formTabs {
        margin-bottom: 40px;

        .tabsBox {
          @include d-flex($jc: start, $al: center);
          gap: 40px;
          position: relative;

          p {
            cursor: pointer;
            color: $color-grey-0;
            text-align: center;
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
          }

          span {
            position: absolute;
            height: 1px;
            bottom: -4px;
            background-color: $color-black-1;
            transition: all 0.3s linear;
          }
        }
      }

      .form {
        @include d-flex($jc: space-between, $al: start);
        flex-direction: column;
        height: 90%;
        //   gap: 40px;

        .inputsBox {
          @include d-flex($jc: start, $al: start);
          flex-direction: column;
          width: 100%;
          gap: 30px;

          .errorInputWrapper {
            width: 100%;
            position: relative;

            .error {
              color: $color-red-0;
              @include font-family-proxima();
              @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
              margin-top: 6px;
              position: absolute;
            }

            label {
              display: block;
              margin-bottom: 6px;
              color: $color-black-1;
              @include font-family-proxima();
              @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
            }

            .phoneError {
              color: $color-red-0;

              margin-top: 6px;
              @include font-family-proxima();
              @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
              position: absolute;
            }

            .countryNames {
              background-color: #fff;
              display: flex;
              gap: 10px;
              align-items: center;
              padding: 10px;
              @include font-family-proxima();
              color: $color-black-5;
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
            }
          }

          .errorInputWrapperPhn {
            &One {
              position: absolute;
              color: $color-grey-0;
              @include font-family-proxima();
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
              top: 38px;
              left: 10px;
              @include d-flex($jc: space-between, $al: center);
              gap: 10px;
              height: 25px;

              p {
                height: 100%;
                background: $color-grey-0;
                width: 1px;
              }
            }
          }

          .textareaBox {
            @include d-flex($jc: start, $al: start);
            flex-direction: column;
            gap: 6px;
            width: 100%;

            label {
              width: 100%;
              color: $color-black-1;
              @include font-family-proxima();
              @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
            }

            textarea {
              border: 1px solid $color-grey-3;
              background: $color-white-0;
              padding: 10px;
              resize: none;
              width: 100%;
              @include font-family-proxima();
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
              max-height: 115px;
            }

            textarea:focus {
              outline: 1px solid $color-black-1;
            }

            textarea::placeholder {
              color: $color-grey-0;
              @include font-family-proxima();
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
            }

            .errorMaxBox {
              @include d-flex($jc: space-between, $al: start);
              width: 100%;

              p {
                flex-basis: 55%;
                color: $color-red-0;
                @include font-family-proxima();
                @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
              }

              span {
                text-align: right;
                flex-basis: 42%;
                color: $color-grey-0;
                @include font-family-proxima();
                @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
              }
            }
          }
        }

        .submit {
          padding: 16px 0;
          background-color: $color-black-1;
          color: $color-white-0;
          width: 100%;
          text-align: center;
          @include font-family-proxima();
          @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1248px) {
  .contactUs {
    &GetInTouch {
      padding: 60px 60px 112px 60px;
    }

    &FormBox {
      padding: 60px 60px 60px 60px;
    }
  }
}

@media screen and (max-width: 992px) {
  .contactUs {
    padding-top: 96px;

    &Container {
      @include d-flex($jc: start, $al: center);
      flex-direction: column;
    }

    &GetInTouch {
      width: 100%;
      padding: 60px;

      .touchContent {
        max-width: 100%;

        .getInTouchHeading {
          margin-bottom: 60px;
        }
      }

      &ImageBox {
        margin: 0 auto;
        max-width: 480px;
      }
    }

    &FormBox {
      padding: 60px;
      width: 100%;

      .formContent {
        margin: 0 auto;
        max-width: 568px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contactUs {
    &GetInTouch {
      padding: 60px;

      .touchContent {
        max-width: 648px;
      }
    }

    &FormBox {
      padding: 60px;

      .formContent {
        padding: 40px;
        max-width: 648px;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .contactUs {
    &GetInTouch {
      padding: 60px 20px;

      .touchContent {
        padding: 0px;
      }
    }

    &FormBox {
      padding: 60px 20px;

      .formContent {
        padding: 20px;
        height: 690px;

        .successMessage {
          padding: 0px;
        }
      }
    }
    .messageHeading {
      h2 {
        @include font-style($fs: 2.4rem !important, $fw: 500, $lh: 140%);
      }
    }
  }
}
