@import "styles/variables.scss";

.cocktailCard {
  max-width: 284px;
  height: 382px;
  @include d-flex($jc: flex-start, $al: center);
  flex-direction: column;
  gap: 16px;
  position: relative;

  &Content {
    position: relative;
    background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/cocktailCard/overlay.webp");

    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    &:hover {
      .cocktailCardContentHide {
        bottom: 0;
      }
      .cocktailTagContainer {
        bottom: -100px;
      }
    }

    &Img {
      img {
        display: block;
      }
    }

    &Main {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 16px 16px 24px 16px;
      background: $background-color-1;
      left: 0;

      &Logo {
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      &Details {
        p {
          color: $color-white-0;
          @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
          @include font-family-proxima();
        }
        h5 {
          color: $color-white-0;
          @include font-style($fs: 1.4rem, $fw: 500, $lh: 140%);
          @include font-family-proxima();
        }
      }
    }

    &Hide {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -100%;
      background-color: $color-white-0;
      border: 1px solid $color-grey-2;
      padding: 24px 12px 16px 16px;
      @include d-flex($jc: space-between, $al: flex-start);
      flex-direction: column;
      transition: all 0.5s ease;
      &Points {
        width: 100%;

        h5 {
          color: $color-black-1;
          @include font-family-miller();
          @include font-style($fs: 2.4rem, $fw: 500, $lh: 138%);
          text-transform: capitalize;
          margin-bottom: 16px;
        }

        .blurBox::before,
        .blurBox::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          height: 14px;
          pointer-events: none;
          z-index: 1;
        }

        .blurBox {
          position: relative;
          overflow-y: scroll;
        }

        .blurBox::before {
          top: 0;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
          );
        }

        .blurBox::after {
          bottom: -1px;
          background: linear-gradient(
            to top,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
          );
        }

        ul {
          box-sizing: border-box;
          height: 185px;
          overflow-y: scroll;
          scroll-behavior: smooth;
          list-style: none;
          list-style-position: inside;
          @include d-flex($jc: flex-start, $al: flex-start);
          flex-direction: column;
          gap: 10px;
          margin-top: 4px;

          li {
            color: $color-black-1;
            @include font-family-proxima();
            @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
            &::marker {
              margin-top: 20px;
            }
          }

          .valueBox {
            @include d-flex($jc: flex-start, $al: flex-start);
            gap: 6px;

            li {
              min-width: 56px;
            }
            span {
              color: $color-black-1;
              @include font-family-proxima();
              @include font-style($fs: 1.5rem, $fw: 400, $lh: 140%);
            }
          }
        }
      }
    }

    .cocktailCardContentImg {
      max-width: 284px;
      height: 344px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover !important;
      }
    }

    .cocktailTagContainer {
      padding: 8px 16px;
      position: absolute;
      border-radius: 32px 0px 0px 32px;
      background: rgba(255, 255, 255, 0.8);
      bottom: 289px;
      right: 0;

      .cocktailTagContent {
        @include d-flex($jc: center, $al: center);
        gap: 8px;
        color: $color-black-0;

        p {
          @include font-style($fs: 1.4rem, $fw: 500, $lh: normal);
          @include font-family-proxima();
        }
      }
    }
  }
  h4 {
    color: $color-black-1;
    @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
    @include font-family-proxima();
  }

  // media queries
  @media (max-width: 768px) {
    max-width: 304px;
    &Content {
      .cocktailCardContentImg {
        max-width: 304px;
        height: 344px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 335px;
    &Content {
      .cocktailCardContentImg {
        max-width: 335px;
        height: 344px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &Hide {
        &Points {
          ul {
            height: 180px;
          }
        }
      }
    }
  }
}
