@import "styles/variables.scss";

.bannerDesktop {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  height: calc(100vh - 166px);
  @include d-flex($jc: center, $al: center);

  &Container {
    @include d-flex($jc: space-between, $al: center);
    gap: 40px;
    width: 100%;

    .bannerDescription {
      max-width: 494px;
      @include d-flex();
      flex-direction: column;
      gap: 40px;

      .bannerHeading {
        @include d-flex($jc: flex-start, $al: flex-start);
        flex-direction: column;
        gap: 12px;
        max-width: 494px;

        h2 {
          color: $color-black-1;
          @include font-style($fs: 4rem, $fw: 500, $lh: 55.2px);
          @include font-family-miller();
          text-transform: capitalize;
          max-width: 401px;
        }

        p {
          color: $color-black-1;
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);
          @include font-family-proxima();
        }
      }
      .ourSpiritsHeading {
        max-width: 539px;
        gap: 16px;

        h2 {
          max-width: 473px;
          color: $color-white-5;
        }

        p {
          max-width: 425px;
          color: $color-grey-3;
        }
      }

      .ourStoryHeading {
        max-width: 588px !important;
        gap: 16px !important;

        h2 {
          max-width: 588px !important;
          color: $color-white-5;
          @include font-style($fs: 4rem, $fw: 500, $lh: 49.68px);
          @include font-family-miller();
          text-transform: capitalize;
        }

        p {
          color: $color-grey-3;
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);
          @include font-family-proxima();
        }
      }
    }
  }
  .ourCocktailsDesktopContainer {
    @include d-flex($jc: center, $al: center);
  }
}

@media only screen and (max-width: 992px) {
  .bannerDesktop {
    height: calc(100vh - 96px) !important;
    @include d-flex($jc: center, $al: flex-start);
    position: relative;
    top: 96px;
    font-weight: 600;

    &Container {
      padding: 80px 0 0 60px !important;

      .tabBanner {
        @include d-flex();
        flex-direction: column;
        max-width: 521px;
        gap: 40px;

        .bannerHeading {
          @include d-flex($jc: center, $al: flex-start);
          flex-direction: column;
          max-width: 521px;
          gap: 12px;

          h2 {
            @include font-style($fs: 3.6rem, $fw: 500, $lh: 38.64px);
            max-width: 401px;
            @include font-family-miller();
          }

          p {
            color: $color-black-0;
            @include font-family-proxima();
            @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);
          }
        }

        .ourSpiritsHeading {
          max-width: 539px;
          gap: 16px;

          h2 {
            max-width: 473px;
            color: $color-white-5;
          }

          p {
            max-width: 425px;
            @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);
            color: $color-grey-3;
          }
        }
        .ourCocktailsHeading {
          h2 {
            max-width: 653px;
            text-wrap: nowrap;
            color: $color-white-0;
          }

          p {
            max-width: 473px;
            text-align: center;
            color: $color-white-0;
          }
        }
      }
      .ourStoryHeading {
        max-width: 494px !important;

        h2 {
          max-width: 408px !important;
          color: $color-white-0;
        }

        p {
          color: $color-white-0 !important;
          @include font-style(
            $fs: 1.8rem !important,
            $fw: 400,
            $lh: 25.2px !important
          );
        }
      }
    }
  }
  .ourCocktailsDesktopContainer {
    @include d-flex($jc: center, $al: center);
  }

  .ourSpiritsDesktopContainer {
    padding-top: 120px !important;
  }

  .ourStoryDesktopContainer {
    padding-top: 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .bannerDesktop {
    height: calc(100vh - 96px) !important;
    @include d-flex($jc: center, $al: flex-start);
    background-position: center;

    &Container {
      padding: 60px 20px 0 !important;

      .mobileBanner {
        @include d-flex();
        flex-direction: column;
        gap: 40px;

        .bannerHeading {
          @include d-flex($jc: center, $al: flex-start);
          flex-direction: column;
          gap: 12px;

          h2 {
            @include font-style($fs: 2.8rem, $fw: 600, $lh: 38.64px);
            max-width: 254px;
            @include font-family-miller();
            color: #100701;
          }

          p {
            color: $color-black-0;
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
          }
        }

        .ourSpiritsHeading {
          gap: 16px;

          h2 {
            max-width: 100%;
            color: $color-white-5;
          }

          p {
            max-width: 100%;
            color: $color-grey-3;
          }
        }

        .ourCocktailsHeading {
          max-width: 100%;

          h2 {
            color: $color-white-5;
          }

          p {
            color: $color-grey-3;
          }
        }

        .ourStoryHeading {
          p {
            max-width: 258px;
            @include font-style($fs: 1.6rem !important, $fw: 400, $lh: 22.4px);
          }
        }
      }
    }
  }
  .ourCocktailsDesktopContainer {
    @include d-flex($jc: center, $al: center);
  }
  .ourSpiritsDesktopContainer {
    padding-top: 80px !important;
  }
}

//

.videoContainer {
  position: relative;
  width: 100%;
  height: calc(100vh - 180px);
  overflow: hidden;
  @include d-flex($jc: center, $al: center);

  .videoBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .ourCocktailsHeading {
    @include d-flex($al: center);
    flex-direction: column;
    max-width: 653px;
    gap: 16px;

    h2 {
      max-width: 653px;
      text-wrap: nowrap;
      color: $color-white-5;
      @include font-style($fs: 4rem, $fw: 500, $lh: 55.2px);
      font-style: italic;
      text-transform: capitalize;
      @include font-family-miller();
    }

    p {
      max-width: 473px;
      text-align: center;
      color: $color-grey-3;
      @include font-style($fs: 1.8rem, $fw: 500, $lh: 25.2px);

      @include font-family-proxima();
    }
  }
}

@media screen and (max-width: 992px) {
  .videoContainer {
    padding-top: 96px;
    height: 100vh;

    .ourCocktailsHeading {
      max-width: 653px;
      gap: 12px;

      h2 {
        max-width: 653px;
        @include font-style($fs: 3.6rem, $fw: 500, $lh: 49.68px);
      }

      p {
        @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);

        @include font-family-proxima();
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .videoContainer {
    padding-top: 96px;
    height: 100vh;

    .ourCocktailsHeading {
      max-width: 335px;
      gap: 12px;

      h2 {
        text-wrap: wrap;
        text-align: center;
        @include font-style($fs: 2.8rem, $fw: 500, $lh: 38.64px);
        max-width: 233px;
      }

      p {
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
      }
    }
  }
}
