@import "styles/variables.scss";

.fcontainer {
  max-width: 1440px;
  margin: 0 auto;
}

.footer {
  background: $color-black-0;

  &Container {
    padding: 64px 56px 20px 56px !important;
  }

  &ContentBox {
    @include d-flex($jc: space-between, $al: start);
    margin-bottom: 32px;

    .leftBox {
      flex-basis: 36.4%;
      // position: relative;
      .companySpiritCocktailBox {
        display: flex;
        gap: 100px;
        padding-bottom: 40px;
        .categoryBox {
          h4 {
            color: $color-white-0;
            @include font-family-proxima();
            @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
            margin-bottom: 20px;
            white-space: nowrap;
          }

          .optionsBox {
            a {
              cursor: default;
              display: block;
              width: fit-content;
            }
            p {
              cursor: pointer;
              width: fit-content;
              color: $color-white-0;
              @include font-family-proxima();
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
              cursor: pointer;
              opacity: 0.7;
              white-space: nowrap;
              margin-bottom: 10px;
            }
          }

          p:hover {
            opacity: 1;
          }

          p:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      .tharaCompanyBox {
        @include d-flex($jc: center, $al: center);
        flex-direction: column;
        gap: 30px;
        position: absolute;
        left: 0;
        bottom: 0;

        .tharaLogoBox {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .socialMediaBox {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            cursor: pointer;
            opacity: 60%;
          }

          img:hover {
            opacity: 100%;
          }
        }
      }
    }
    .rightBox {
      flex-basis: 46.4%;

      .imgBox {
        .resfooterImg {
          display: none;
        }
      }
    }
  }
  &BottomBox {
    display: flex;
    justify-content: space-between;

    p {
      color: $color-white-0;
      @include font-family-proxima;
      @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
      opacity: 0.9;
    }

    .policy {
      display: flex;
      gap: 20px;

      span {
        color: $color-white-0;
        @include font-family-proxima;
        @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
        cursor: pointer;
        opacity: 0.7;
        a {
          color: $color-white-0;
          font-size: 13px;
        }
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  //footer
  .footer {
    &Container {
      padding: 60px 60px 20px 60px !important;
    }
    &ContentBox {
      flex-direction: column-reverse;
      gap: 40px;

      .leftBox {
        border-bottom: 1.5px solid rgba(255, 255, 255, 0.2);
        width: 100%;

        .tharaCompanyBox {
          margin-bottom: 40px;

          .tharaLogoBox {
            height: 65px;
            width: 68px;
          }

          img {
            opacity: 40%;
          }
        }
      }

      .rightBox {
        .imgBox {
          .resfooterImg {
            display: block;
          }
          .webfooterImg {
            display: none;
          }
        }
      }
    }

    &BottomBox {
      flex-direction: column;
      gap: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  //footer
  .footer {
    &ContentBox {
      .leftBox {
        .companySpiritCocktailBox {
          column-gap: 100px;
          flex-wrap: wrap;
          row-gap: 60px;
          justify-content: space-between;
        }

        .tharaCompanyBox {
          width: fit-content;
        }
      }

      .rightBox {
        .imgBox {
          .resfooterImg {
            display: none;
          }

          .webfooterImg {
            display: block;
          }
        }
      }
    }

    &BottomBox {
      .policy {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .footer {
    &Container {
      padding: 60px 20px 20px 20px !important;
    }

    &ContentBox {
      .leftBox {
        padding-bottom: 40px;

        .companySpiritCocktailBox {
          justify-content: start;
          margin-bottom: 109px;
        }

        // .tharaCompanyBox {
        //   bottom: 0;
        // }
      }
    }
  }
}
