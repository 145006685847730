@import "styles/variables.scss";

// experience
.experience {
  background-color: $color-white-0;

  &Container {
    @include d-flex($jc: center, $al: center);
    padding: 60px 20px !important;
    flex-direction: column;
    gap: 60px;
    margin-top: 120px;

    .experienceHeading {
      // max-width: 700px;
      margin: 0 auto;
      @include d-flex($jc: center, $al: center);
      text-align: center;

      p {
        text-align: center;
      }

      .mobileExperience {
        @include font-style($fs: 2.8rem, $fw: 500, $lh: 38.64px);
        text-align: center;
        //   max-width: 229px;
      }
    }

    .experienceCards {
      display: flex;
      gap: 80px;
      width: 100%;
      @include d-flex($jc: center);

      .experienceCard {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
        max-width: 300px;
        width: 100%;
        min-height: 375px;
        border-radius: 150px 150px 0px 0px;
        border: 1px solid $color-white-5;
        box-shadow: 0px 24px 20px 0px $background-color-3;
        background: $background-color-4;

        .cardBackgroundImage {
          position: absolute;
          right: 0;
          top: 0;
        }

        .cardSecondBackgroundImage {
          position: absolute;
          // top: -18px;
          // left: 0;
          rotate: 319deg;
          position: absolute;
          left: -79px;
          bottom: 184px;
          background: linear-gradient(
            488deg,
            #fcfecc 0%,
            #fdfbd4 30%,
            #feeedf 59.5%,
            #e8e8ff 100%
          );
        }

        &Deatails {
          padding: 32px 32px 43px;

          .cardDetails {
            @include font-family-proxima();
            max-width: 236px;
            padding-top: 32px;
            @include d-flex();
            flex-direction: column;
            gap: 20px;

            h4 {
              color: $color-black-1;
              @include font-style($fs: 1.8rem, $fw: 500, $lh: 25.2px);
              max-width: 159px;
            }

            p {
              color: $color-grey-0;
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
            }
          }
        }
      }
    }

    .tabExperience {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 60px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .experienceCardDeatails {
    padding: 32px 32px 54px !important;
  }
}

@media only screen and (max-width: 568px) {
  .experienceContainer {
    margin-top: 96px;
  }

  .experienceHeading {
    .experiencedetails {
      max-width: 335px;
    }
  }
  .experienceCardDeatails {
    padding: 32px 32px 54px !important;
  }

  .experienceCardDeatails .cardDetails h4 {
    font-weight: 600 !important;
    opacity: 0.8;
  }
}

// ourSpirits
.ourSpirits {
  padding: 40px 0 0px;
  background-color: $color-white-2;

  .ourSpirits {
    p {
      max-width: 682px;
      text-align: center;
    }
  }

  &Slider {
    position: relative;
    padding: 50px 0 40px 100px;
    margin: 60px 0 70px;

    &::after {
      content: "";
      position: absolute;
      width: 454px;
      height: 519px;
      background: $color-white-0;
      border: 1px solid $color-black-2;
      top: 0;
      left: calc(50% - 227px);
    }

    .swiper-slide {
      scale: 0.77;
      opacity: 0.5;
      transition: all 0.5s ease;

      .ourSpiritsSliderBoxDetails {
        opacity: 0;
        transition: all 0.5s ease;
        pointer-events: none;
      }
    }
    .swiper-slide-next {
      transform: translateX(130px);
    }

    .swiper-slide-active {
      transform: translate3d(-145px, 0, 0) !important;
      scale: 1;
      transition: all 0.5s ease;
      opacity: 1;

      .ourSpiritsSliderBoxDetails {
        transition: all 0.5s ease 0.3s;
        opacity: 1;
        pointer-events: inherit;
      }

      .ourSpiritsSliderBoxImg {
        min-width: 102px;
        img {
          width: 100%;
        }
      }
    }

    &Box {
      position: relative;
      @include d-flex($jc: flex-start, $al: center);
      gap: 52px;
      height: 390px;

      &Img {
        max-width: 102px;
      }

      &Details {
        position: absolute;
        right: -65px;

        &Img {
          max-width: 121px;
          padding-bottom: 14px;
        }

        &Price {
          .priceSection {
            padding-bottom: 8px;
            border-bottom: 1px solid $color-grey-3;
            width: 100%;
            margin-bottom: 20px;

            &Details {
              padding-bottom: 16px;

              h4 {
                @include font-family-miller();
                color: $color-black-1;
                @include font-style($fs: 2.8rem, $fw: 500, $lh: 138%);
                text-transform: capitalize;
              }

              p {
                @include font-family-proxima();
                color: $color-grey-0;
                @include font-style($fs: 1.3rem, $fw: 400, $lh: 140%);
                padding-top: 2px;
              }
            }

            &Tag {
              @include font-family-proxima();
              @include d-flex($jc: flex-start, $al: center);
              gap: 12px;

              p {
                color: $color-black-1;
                @include font-style($fs: 2rem, $fw: 500, $lh: 140%);
              }

              span {
                color: $color-grey-0;
                @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
                text-transform: uppercase;
              }
            }
          }

          .buyOption {
            p {
              color: $color-grey-0;
              @include font-family-proxima();
              @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
              max-width: 298px;
              margin-bottom: 40px;
            }
          }
        }
      }
    }

    &Arrows {
      &Prev {
        position: absolute;
        left: -10px;
        top: 50%;
        z-index: 9;
        cursor: pointer;
        background: #fff;
        padding: 8px;
        width: 56px;
        height: 56px;
        border-radius: 50%;

        img {
          transition: all 0.4s ease;
          width: 40px;
          height: 40px;
        }

        &:hover {
          img {
            transform: translateX(-3px);
            transition: all 0.4s ease;
          }
        }
      }

      &Next {
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 9;
        cursor: pointer;
        background: #fff;
        padding: 8px;
        border-radius: 50%;
        width: 56px;
        height: 56px;

        img {
          transition: all 0.4s ease;
          width: 40px;
          height: 40px;
        }

        &:hover {
          img {
            transform: translateX(3px);
            transition: all 0.4s ease;
          }
        }
      }
    }

    .ourSpiritsSliderArrows {
      // padding-top: 20px;
      padding: 20px 0;
    }
  }

  @media screen and (max-width: 1024px) {
    .ourSpiritsSliderBoxDetails {
      margin-left: 110px !important;
    }
    .ourSpiritsSliderBox {
      min-height: 408px;
    }
    .ourSpiritsSliderBoxDetails {
      right: -148px;
    }
    .ourSpiritsSliderBox {
      left: -26px;
    }
  }

  // responsive
  @media (max-width: 992px) {
    .ourSpiritsContainer {
      padding: 20px 20px 60px;
    }

    &Slider {
      padding: 152px 0;
      margin: 0;

      &::after {
        top: 80px;
        width: 452px !important;
        height: 564px !important;

        left: calc(50% - 200px);
      }

      .swiper-slide-active {
        transform: none !important;
      }

      &Box {
        justify-content: center;
        height: 100%;
        gap: 52px;
        max-width: 504px;
        margin: 0 auto;

        &Img {
          max-width: 102px !important;
          min-width: auto !important;
        }

        &Details {
          position: static;
          width: 298px;

          &Price {
            .priceSection {
              &Details {
                h4 {
                  font-size: 2.8rem;
                }
              }

              &Tag {
                p {
                  font-size: 2rem;
                }
              }
            }

            .buyOption {
              .blurText::before,
              .blurText::after {
                content: "";
                position: absolute;
                left: 0;
                right: 12px;
                height: 12px;
                pointer-events: none;
                z-index: 1;
              }

              p {
                font-size: 1.8rem;
                width: 100%;
              }
            }
          }
        }
      }

      &Arrows {
        &Prev {
          top: auto;
          padding: 8px;
          width: 56px;
          height: 56px;
          bottom: 0;
          left: calc(50% - 76px);
          // left: calc(50% - 102px);
        }

        &Next {
          top: auto;
          bottom: 0;
          right: calc(50% - 76px);
          padding: 8px;
          width: 56px;
          height: 56px;
          // right: calc(50% - 102px);
        }
      }
    }
    .ourSpiritsSliderBoxDetails {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 767px) {
    &Slider {
      padding: 103px 0;

      &::after {
        width: 305px !important;
        height: 419px !important;
        left: calc(50% - 138px);
      }

      &Box {
        &Img {
          max-width: 58px !important;
          min-width: auto !important;
        }
      }
    }
  }

  @media (max-width: 568px) {
    .ourSpiritsHeading {
      h2 {
        max-width: 300px;
        font-weight: 600;
      }
    }

    .ourSpiritsSliderBox {
      left: -4px;
      overflow: scroll;
      gap: 32px;
    }

    .ourSpiritsSlider::after {
      min-height: 450px;
    }

    .priceSection {
      &Details {
        h4 {
          font-size: 2rem;
          font-weight: 600;
        }
      }
      &Tag {
        p {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }

    .buyOption {
      p {
        font-size: 1.6rem;
        max-width: 225px;
      }
    }
    .ourSpiritsSliderBoxDetails {
      width: 100%;
    }
    .ourSpiritsSliderBoxDetails {
      width: 229px;
    }
  }
}

// drinks
.recipe {
  background: $background-color-0;
  padding: 60px 0 74px;

  .imageContainer {
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease;
    }
  }

  .imageButtonPrev {
    position: absolute;
    top: 130px;
    left: 29%;
    z-index: 9;
    cursor: pointer;
    box-shadow: 0px 0px 20.1px 0px rgba(161, 160, 160, 0.27);
    overflow: hidden;
    transition: all 0.5s ease;
    border-radius: 50%;

    img {
      display: block;
      height: auto;
      object-fit: cover;
      transition: all 0.5s ease;
      width: 100%;
    }

    &:hover {
      opacity: 1;
      transition: all 0.5s ease;
      transform: scale(1.1);
    }
  }

  .imageButtonNext {
    position: absolute;
    top: 130px;
    right: 0;
    z-index: 9;
    cursor: pointer;
    box-shadow: 0px 0px 20.1px 0px rgba(161, 160, 160, 0.27);
    overflow: hidden;
    transition: all 0.5s ease;
    border-radius: 50%;

    img {
      display: block;
      height: auto;
      object-fit: cover;
      transition: all 0.5s ease;
      width: 100%;
    }

    &:hover {
      opacity: 1;
      transition: all 0.5s ease;
      transform: scale(1.1);
    }
  }

  &Button {
    @include d-flex(center, center);
    margin-top: 60px;
  }

  &Container {
    @include d-flex(flex-start, flex-start);
    gap: 80px;
    position: relative;
  }

  &Content {
    // flex-basis: 55%;
    max-width: 277px;
    width: 100%;

    h2 {
      color: $color-black-1;
      @include font-style(3.6rem, 500, 138%);
      @include font-family-miller();
      text-transform: capitalize;
      margin-bottom: 16px;
    }

    p {
      color: $color-black-1;
      @include font-style(1.8rem, 400, 140%);
      @include font-family-proxima();
      margin-bottom: 50px;
    }
  }

  &Swiper {
    width: 260px;
    height: 280px;
    position: relative;

    &Profile {
      @include d-flex();
      gap: 8px;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: 16px 0 37px 16px;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);

      img {
        width: 32px;
        height: 32px;
      }

      &Data {
        p {
          @include font-style(1.2rem, 400, 140%);
          @include font-family-proxima();
          margin-bottom: 2px;
          opacity: 0.7;
          color: $color-white-0;
          text-align: justify;
        }

        span {
          color: $color-white-0;
          @include font-style(1.2rem, 500, 140%);
          @include font-family-proxima();
        }
      }
    }
  }

  &Swipe {
    @include d-flex(flex-start, center);
    flex-direction: column;
    gap: 18px;
    position: relative;
    cursor: pointer;
  }

  &Name {
    label {
      color: $color-black-1;
      @include font-style(2rem, 500, 140%);
      @include font-family-proxima();
    }
  }

  .hide {
    display: none;
  }

  // responsive
  @media (max-width: 992px) {
    &Container {
      display: block;
      text-align: center;
    }

    &Content {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      h2 {
        max-width: 366px;
        margin: 0 auto;
      }
    }

    &Swipe {
      width: fit-content;
    }

    .imageContainer {
      min-width: 100%;
    }

    .imageButtonPrev {
      left: 0;
      top: 48%;
    }

    .imageButtonNext {
      right: 0;
      top: 48%;
    }
  }

  @media (max-width: 768px) {
    &Container {
      padding: 0 96px !important;
    }

    .imageButtonPrev {
      left: 9.5%;
      top: 48%;
    }

    .imageButtonNext {
      right: 9.1%;
      top: 48%;
    }

    &Swiper {
      width: 272px;
      height: 292px;
    }
  }

  @media (max-width: 568px) {
    .swiper-horizontal {
      padding: 0 30px;
    }

    .recipeSwipe {
      width: 100%;
      margin: 0 auto;
    }

    &Container {
      padding: 0px 20px !important;
    }

    .imageButtonPrev {
      left: 7.5%;
      top: 48%;
    }

    .imageButtonNext {
      right: 7.5%;
      top: 48%;
    }

    &Swiper {
      width: -webkit-fill-available;
      height: 292px;
    }

    &Content {
      h2 {
        @include font-style(2.8rem, 600, 138%);
      }

      p {
        @include font-style(1.6rem, 400, 140%);
        margin-bottom: 60px;
      }
    }

    &Swipe {
      width: auto;
    }
  }
}

//community
.community {
  &Container {
    padding: 60px 0;
  }

  &Heading {
    padding: 0 20px;
    margin-bottom: 60px;
  }

  .autoplayBox {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

// happy Customer

.review {
  background-color: $color-white-2;

  .ourCustomerHeading {
    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 568px) {
  .review {
    padding: 40px 0 !important;
    .ourCustomerHeading {
      p {
        @include font-style(1.6rem, 400, 140%);
        text-align: center;
        min-width: 335px;
      }
    }
  }
}

// ourLifeStyle
.lifeStyle {
  background: $color-grey-5;

  &Container {
    padding: 60px 20px !important;

    .heading {
      p {
        text-align: center;
      }
    }
  }

  &Boxes {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 60px;

    .Box {
      padding: 32px;
      flex-basis: 49%;
      border: 1px solid #e1e5ea;
      background: $color-white-0;

      .headingBox {
        @include d-flex($jc: start, $al: center);
        gap: 8px;
        margin-bottom: 20px;

        h3 {
          color: $color-black-1;
          @include font-family-proxima();
          @include font-style($fs: 2rem, $fw: 500, $lh: 140%);
        }

        span {
          color: $color-black-1;
          @include font-family-proxima();
          @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
          text-transform: uppercase;
          display: block;
          padding: 8px 16px;
          background: $color-grey-2;
        }
      }

      p {
        margin-bottom: 32px;
        color: $color-grey-0;
        @include font-family-proxima();
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
      }

      .imgBox {
        @include d-flex($jc: center, $al: center);
        img {
          max-width: 240px;
          max-height: 220px;
        }
      }

      .shirtImgBox {
        background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/jacketBackground.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-color: $color-white-1;
      }

      .bagImgBox {
        background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/whitecurl.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-color: #ededed;
        height: 220px;
      }
    }
  }
}

.modalOverlay {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  background-color: rgba(0, 0, 0, 0.25);

  @include d-flex($jc: center, $al: start);

  .modal {
    background-color: $color-white-0;
    position: relative;
    max-width: 594px;

    &TopBox {
      padding: 32px 56px 26px 56px;

      .tharraLogoBox {
        margin-bottom: 29px;
        @include d-flex($jc: center, $al: center);
      }

      #wellComeTharra {
        color: $color-grey-0;
        text-align: center;
        @include font-family-proxima();
        @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
      }

      .headingBox {
        margin-top: 8px;
        margin-bottom: 51px;

        h1 {
          color: $color-black-1;
          text-align: center;
          @include font-family-proxima();
          @include font-style($fs: 2.8rem, $fw: 500, $lh: 140%);
        }

        h3 {
          color: $color-black-1;
          text-align: center;
          @include font-family-proxima();
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        }
      }

      .rememberMeBox {
        @include d-flex($jc: center, $al: center);
        flex-direction: column;
        gap: 12px;
        margin-bottom: 32px;

        .checkBox {
          display: flex;
          align-items: center;
          gap: 12px;
          color: $color-black-1;
          text-align: center;
          @include font-family-proxima();
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);

          input {
            height: 16px;
            width: 16px;
            cursor: pointer;
            accent-color: $color-black-0;
          }
        }

        p {
          color: $color-grey-0;
          text-align: center;
          @include font-family-proxima();
          @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
        }
      }

      #tharraSuggetion {
        color: $color-grey-0;
        text-align: center;
        @include font-family-proxima();
        @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
        margin-bottom: 30px;
      }

      button {
        width: 100%;
        padding: 10px 20px;
        background-color: $color-black-1;
        border: none;
        outline: none;
        cursor: pointer;
        color: $color-white-0;
        @include font-family-proxima();
        @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
      }
    }

    &BottomBox {
      padding: 16px 32px;
      @include d-flex($jc: center, $al: center);
      position: relative;

      ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0.2px;
        background-color: $color-grey-0;
        opacity: 0.2;
      }

      p {
        color: $color-black-1;
        text-align: center;
        @include font-family-proxima();
        @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  //lifeStyle
  .lifeStyle {
    &Container {
      padding: 60px !important;
    }

    &Boxes {
      flex-direction: column;
    }
  }

  //modal
  .modalOverlay {
    padding: 60px 86px;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .lifeStyle {
    &Container {
      .heading {
        margin-bottom: 60px;
      }
    }

    &Boxes {
      max-width: 580px;
      margin: 0 auto;
      gap: 60px;

      .Box {
        .headingBox {
          h3 {
            @include font-style($fs: 2rem, $fw: 500, $lh: 140%);
          }

          span {
            @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
          }
        }

        p {
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        }

        .imgBox {
          img {
            max-width: 240px;
            max-height: 220px;
          }
        }
      }
    }
  }

  .modalOverlay {
    padding: 60px 20px;
    overflow: scroll;

    .modal {
      width: 335px;

      &TopBox {
        padding: 32px 20px 26px 20px;

        .tharraLogoBox {
          display: none;
        }

        .headingBox {
          h1 {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .lifeStyle {
    &Container {
      padding: 60px 20px !important;

      .heading {
        h2 {
          max-width: 270px;
        }
      }
    }
  }

  .modalOverlay {
    .modal {
      max-width: 335px;

      &TopBox {
        #wellComeTharra {
          @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
        }

        .headingBox {
          h1 {
            @include font-style($fs: 2.4rem, $fw: 500, $lh: 140%);
          }

          h3 {
            @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
          }
        }

        .rememberMeBox {
          @include d-flex($jc: center, $al: center);
          flex-direction: column;
          gap: 12px;
          margin-bottom: 32px;

          .checkBox {
            display: flex;
            align-items: center;
            gap: 12px;
            color: $color-black-1;
            text-align: center;
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);

            input {
              height: 16px;
              width: 16px;
              cursor: pointer;
              accent-color: $color-black-0;
            }
          }

          p {
            color: $color-grey-0;
            text-align: center;
            @include font-family-proxima();
            @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
          }
        }

        #tharraSuggetion {
          color: $color-grey-0;
          text-align: center;
          @include font-family-proxima();
          @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
          margin-bottom: 30px;
        }

        button {
          width: 100%;
          padding: 10px 20px;
          background-color: $color-black-1;
          border: none;
          outline: none;
          cursor: pointer;
          color: $color-white-0;
          @include font-family-proxima();
          @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
        }
      }

      &BottomBox {
        padding: 16px 20px;

        p {
          @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
        }
      }
    }
  }
}
