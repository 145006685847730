@import "styles/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  overscroll-behavior: none;
}
body {
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
}

html,
body {
  font-size: 10px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

section {
  width: 100%;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 20px;
}

.review {
  padding: 40px 20px;
  @include font-family-proxima();

  .commonHeading {
    margin-bottom: 60px;
  }

  // media queries
  @media (max-width: 768px) {
    padding: 40px;
  }
}

.commonHeading {
  @include d-flex(center, center);
  flex-direction: column;
  gap: 16px;

  h2 {
    color: $color-black-1;
    @include font-style($fs: 3.6rem, $fw: 600, $lh: 138%);
    @include font-family-miller();
    text-transform: capitalize;
  }

  p {
    color: $color-grey-0;
    @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
    @include font-family-proxima();
  }
}

@media (max-width: 568px) {
  .commonHeading {
    text-align: center;

    h2 {
      font-size: 2.8rem;
    }
    p {
      font-size: 1.6rem;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
