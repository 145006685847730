@import "styles/variables.scss";

.nearBy {
  &Container {
    padding: 60px 20px !important;
  }

  &Box {
    @include d-flex($jc: space-between, $al: center);
    background-color: $color-yellow-0;
    padding: 60px 60px;
    background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/pattern.webp");
    background-position: 15%;
    background-repeat: no-repeat;
    background-size: auto 153%;

    &Header {
      h2 {
        color: $color-black-1;
        @include font-family-miller();
        @include font-style($fs: 3.6rem, $fw: 500, $lh: 138%);
        font-style: italic;
        text-transform: capitalize;
        margin-bottom: 8px;
      }

      p {
        color: $color-black-1;
        @include font-family-proxima();
        @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .nearBy {
    &Container {
      padding: 60px !important;
    }

    &Box {
      padding: 40px;
      flex-direction: column;
      align-items: start !important;
      gap: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nearBy {
    &Container {
      padding: 60px !important;
    }

    .productDetailsNearBy {
      padding-top: 0px !important;
    }

    &Box {
      padding: 40px !important;
      align-items: flex-start !important;
      padding: 60px 40px;
      background-position: 9%;
      background-size: auto 116%;
    }
  }
}

@media screen and (max-width: 568px) {
  .nearBy {
    &Container {
      padding: 60px 20px !important;
    }

    &Box {
      background-image: none !important;

      &Header {
        h2 {
          @include font-style($fs: 2.8rem, $fw: 600, $lh: 140%);
        }
        p {
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        }
      }
    }
  }
}
