@import "styles/variables.scss";

// discover india
.discover {
  background-color: $color-black-4;
  position: relative;

  &Container {
    @include d-flex($jc: space-between, $al: center);
    padding: 90px 0 90px 120px;
  }

  &Details {
    padding: 71px 0;

    h2 {
      color: $color-white-0;
      @include font-style($fs: 3.6rem, $fw: 500, $lh: 138%);
      @include font-family-miller();
      text-transform: capitalize;
    }

    p {
      color: $color-white-0;
      @include font-family-proxima();
      @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
      -webkit-text-stroke-color: $color-black-0;
      padding: 16px 0 32px;
    }

    .discoveryButton {
      max-width: 156px;
    }
  }

  &Img {
    position: absolute;
    height: 100%;
    bottom: 0;
    right: 0;
    width: 33.4%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  //   media queries
  @media (max-width: 1128px) {
    &Details {
      p {
        max-width: 480px;
      }
    }
  }
  @media (max-width: 992px) {
    &Container {
      flex-direction: column;
      align-items: flex-start;
      gap: 55px;
      padding: 60px !important;
    }

    &Details {
      padding: 0;
      p {
        max-width: 100%;
      }
    }

    &Img {
      position: static;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    &Container {
      padding: 60px 20px !important;
    }

    &Details {
      h2 {
        font-size: 2.8rem;
      }

      p {
        font-size: 1.6rem;
      }
    }
  }
}

// ourStory
.ourStory {
  background: $color-white-4;
  padding: 40px 0;

  .container {
    @include d-flex(space-between, flex-start);
    gap: 60px;
  }

  &Img {
    max-width: 580px;
    padding: 28px 0;
    align-self: center;

    img {
      width: 409px;
      height: 390px;
    }
  }

  &Description {
    max-width: 540px;

    h2 {
      color: $color-black-1;
      @include font-style(3.6rem, 500, 138%);
      font-style: italic;
      text-transform: capitalize;
      @include font-family-miller();
      margin-bottom: 40px;
    }

    p {
      @include font-family-proxima();
      color: $color-grey-0;
      @include font-style(1.8rem, 400, 140%);
      margin-bottom: 32px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  @media (max-width: 992px) {
    .container {
      display: block;
      padding: 20px 60px;

      .description {
        text-align: center;
        margin-bottom: 0;
      }
    }

    &Img {
      padding: 0;
      max-width: 100%;

      img {
        width: 580px;
        height: 556.62px;
      }
    }

    &Description {
      max-width: 100%;

      &Img {
        margin: 60px auto 60px auto;
        max-width: 580px;
        @include d-flex($jc: center);
      }

      h2 {
        text-align: center;
        margin-bottom: 16px;
      }

      p {
        &:first-of-type {
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 568px) {
    padding: 40px 0px 60px 0px !important;

    .container {
      padding: 20px 20px 0 20px !important;
    }
    &Description {
      &Img {
        height: 322px;

        img {
          width: 335px;
          height: 321.5px;
        }
      }

      h2 {
        font-size: 2.8rem;
      }

      p {
        @include font-style(1.6rem, 400, 140%);

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}

// birthTharra

.birthTharra {
  &Container {
    @include d-flex($jc: space-between);
    padding: 60px 20px !important;

    .birthTharraContent {
      max-width: 540px;
      @include d-flex();
      flex-direction: column;
      gap: 32px;

      .birthTharraHeading {
        @include d-flex();
      }

      .birthTharraDescription {
        gap: 32px;

        span {
          color: $color-grey-0;
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);
          @include font-family-proxima();
        }
      }
    }

    .birthTharraImage {
      padding: 40px 0;

      img {
        max-width: 580px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .birthTharra {
    &Container {
      @include d-flex($jc: center);
      margin-top: 96px !important;
      padding: 60px !important;

      .birthTharraContent {
        max-width: 100%;

        .birthImageContainer {
          @include d-flex(flex-start, center);
          flex-direction: column;
          gap: 60px;
        }
      }

      .birthTharraHeading {
        width: 100%;
        @include d-flex($jc: center, $al: center !important);
      }

      .birthTharraDescription {
        text-align: start;

        p,
        span {
          @include font-style($fs: 1.8rem !important, $fw: 400, $lh: 25.2px);
          @include font-family-proxima();
        }
      }

      .birthTharraImage {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 568px) {
  .birthTharra {
    &Container {
      padding: 60px 20px !important;

      .birthTharraDescription {
        p,
        span {
          @include font-style(
            $fs: 1.6rem !important,
            $fw: 400,
            $lh: 22.4px !important
          );
          @include font-family-proxima();
        }
      }

      .birthTharraImage {
        img {
          max-width: 335px !important;
        }
      }
    }
  }
}

//ourStoryPlaces

.ourStoryPlaces {
  background-color: $color-white-4;

  &Container {
    @include d-flex($jc: space-between);
    gap: 40px;
    padding: 60px 20px !important;

    .placesContainer {
      max-width: 373px;
      height: 473px;
      background-color: $color-white-0;
      border-radius: 200px 200px 0px 0px;
      padding: 72px 32px 40px;
      @include d-flex($jc: center, $al: center);
      flex-direction: column;
      gap: 40px;

      .placesImage {
        width: 220px;
        height: 220px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }

      .placesContent {
        text-align: center;
        gap: 10px;

        h2 {
          @include font-style($fs: 1.8rem, $fw: 500, $lh: 22.4px);
          @include font-family-proxima();
          font-style: normal;
        }

        p {
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .ourStoryPlaces {
    &Container {
      @include d-flex($jc: center);
      flex-wrap: wrap;

      .placesContainer {
        padding: 64px 32px 32px;
        width: 314px;

        .placesContent {
          h2 {
            @include font-style($fs: 1.8rem, $fw: 600, $lh: 22.4px);
          }

          p {
            @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ourStoryPlaces {
    &Container {
      .placesContainer {
        height: 432px;
        .placesImage {
          height: 186px;
          width: 186px;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 568px) {
  .ourStoryPlaces {
    &Container {
      @include d-flex($jc: center);
      flex-wrap: wrap;

      .placesContainer {
        padding: 64px 32px 32px;
      }
    }
  }
}
