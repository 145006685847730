@import "styles/variables.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-white-0;
  border: 1px solid #404b5a;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  top: 1px;
  left: 1px;
  background-color: #404b5a;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #404b5a;
}

input:checked + .slider:before {
  transform: translateX(20px);
  background-color: $color-white-0;
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
