@import "styles/variables.scss";

.videoIntro {
  margin: 0 auto;
  width: 200px;
  height: 100vh;
  @include d-flex($jc: center, $al: center);
  gap: 16px;
  flex-direction: column;
  transition: transform 0.9s ease-in-out;

  &.end {
    transform: translateY(-100%);
  }

  .loadingProgress {
    p {
      color: $color-grey-10;
      @include font-family-miller();
      @include font-style($fs: 2.4rem, $fw: 400, $lh: 120%);
      font-size: 36px;
      font-style: italic;
    }
  }
}
