// @import url("https://use.typekit.net/kpz4zbl.css"); // miller-banner
//  @import url("https://use.typekit.net/fnh5lnw.css"); // proxima-nova

@import url("https://use.typekit.net/cri0jxa.css"); // miller-banner
@import url("https://use.typekit.net/iml3jcc.css"); // proxima-nova

// variables
$color-white-0: #ffffff;
$color-white-1: #fff3d4;
$color-white-2: #f9fafc;
$color-white-3: #fafafa;
$color-white-4: #f5f7f7;
$color-white-5: #f6f7f9;

$color-black-0: #000;
$color-black-1: #181d25;
$color-black-2: #edf0f200;
$color-black-3: #0000000a;
$color-black-4: #0d121e;
$color-black-5: #101828;

$color-grey-0: #606e80;
$color-grey-1: #efefef;
$color-grey-2: #edf0f2;
$color-grey-3: #e1e5ea;
$color-grey-5: #f7f8fa;
$color-grey-4: #404b5a;
$color-grey-7: #d3dbe4;
$color-grey-8: #d3dbe4;
$color-grey-9: #c3ced7;
$color-grey-10: #6f6e67;

$color-voilet-0: #ffe3e3;

$color-rose-0: #fff0cf;

$color-yellow-0: #f3ebd6;

$color-blue-0: #0031e0;

$color-red-0: #c00;

$background-color-0: linear-gradient(
  104deg,
  #fffcf7 -2.32%,
  #fff0d2 29.74%,
  #ffeabf 36.16%,
  #fff0d2 47.43%,
  #fff0d2 60.17%
);

$background-color-1: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.25) 50%,
  rgba(0, 0, 0, 0.7) 100%
);

$background-color-3: rgba(233, 232, 232, 0.1);

$background-color-4: linear-gradient(
  38deg,
  rgba(255, 255, 255, 0.6) 11.7%,
  rgba(255, 255, 255, 0.18) 101.87%
);

// Flex mixin
@mixin d-flex($jc: flex-start, $al: flex-start) {
  display: flex;
  align-items: $al;
  justify-content: $jc;
}

@mixin font-style($fs: 1.6rem, $fw: 400, $lh: 120%) {
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
}

@mixin font-family-miller() {
  font-family: "miller-banner", serif;
  font-style: italic;
}

@mixin font-family-proxima() {
  font-family: "proxima-nova", sans-serif;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: all 0.5s ease;
}
