@import "styles/variables.scss";

.drink {
  background: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/ourCocktail/bgImage.webp"),
    $color-white-4;
  background-repeat: no-repeat;
  background-position: top right;

  &Container {
    @include d-flex();
    padding: 100px 20px 0 !important;
    gap: 52px;
  }

  &Data {
    &ComingSoon {
      margin-bottom: 80px;

      h2 {
        color: $color-black-1;
        @include font-family-miller();
        @include font-style(3.6rem, 500, 138%);
        text-transform: capitalize;
        @include d-flex();
        flex-direction: column;
        margin-bottom: 16px;
      }
    }

    &Para {
      color: $color-grey-0;
      @include font-style(1.8rem, 400, 140%);
      @include font-family-proxima();

      .bold {
        @include font-family-proxima();
        @include font-style(1.8rem, 600, 140%);
        color: $color-grey-0;
      }
    }
  }

  &Description {
    @include d-flex();
    flex-direction: column;
    gap: 36px;

    //  &Para {
    //    .bold {
    //      display: block;
    //      @include font-family-proxima();
    //      @include font-style(1.8rem, 500, 140%);
    //      color: $color-grey-0;
    //    }
    //  }
  }

  // responsive
  @media (max-width: 992px) {
    &Container {
      flex-direction: column;
      padding: 60px 60px 0 !important;
      gap: 120px;
    }
  }

  @media (max-width: 800px) {
    background: none, $color-white-4;
  }

  @media (max-width: 568px) {
    &Container {
      padding: 40px 20px 0 !important;
      gap: 40px;
    }

    &Data {
      &ComingSoon {
        margin-bottom: 60px;

        h2 {
          @include font-style(2.8rem, 500, 138%);
          margin-bottom: 8px;
        }
      }

      &Para {
        @include font-style(1.6rem, 400, 140%);
      }
    }

    &Description {
      gap: 24px;
    }
  }
}
