@import "styles/variables.scss";

//productInformation
.productInfo {
  &Container {
    padding: 60px 20px !important;
    @include d-flex($jc: start, $al: start);
    gap: 60px;

    .imageBox {
      flex-basis: 37.59%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .detailBox {
      flex-basis: 57.64%;
      @include d-flex($jc: space-between, $al: start);
      flex-direction: column;
      align-self: stretch;
      height: auto;
      // padding-bottom: 40px;

      &Top {
        @include d-flex($jc: start, $al: start);
        flex-direction: column;
        gap: 24px;

        h1 {
          color: $color-black-1;
          @include font-family-miller();
          @include font-style($fs: 3.6rem, $fw: 500, $lh: 138%);
        }

        p {
          color: $color-grey-0;
          @include font-family-proxima();
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
        }

        .headingBox {
          span {
            color: $color-grey-0;
            @include font-family-proxima();
            @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
            font-style: italic;
          }
        }
      }

      &Bottom {
        @include d-flex($jc: start, $al: center);
        gap: 12px;

        .nameBox {
          @include d-flex($jc: start, $al: start);
          flex-direction: column;
          gap: 4px;

          p {
            color: $color-grey-0;
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
          }

          h4 {
            color: $color-black-1;
            @include font-family-proxima();
            @include font-style($fs: 2rem, $fw: 500, $lh: 140%);
          }
        }
      }
    }
  }
  // media queries
  @media (max-width: 992px) {
    &Container {
      .imageBox {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

//ingredient
.ingredient {
  &Container {
    padding: 60px 20px !important;

    .topBox {
      @include d-flex($jc: start, $al: start);
      gap: 40px;
      margin-bottom: 40px;

      h1 {
        color: $color-black-1;
        @include font-family-miller();
        @include font-style($fs: 3.6rem, $fw: 500, $lh: 138%);
        margin-bottom: 16px;
      }

      .valueNameWrapper {
        @include d-flex($jc: start, $al: start);
        padding: 16px 0;
        gap: 24px;
        border-bottom: 1px solid $color-grey-3;

        h1 {
          padding: 0px;
        }

        p {
          color: $color-black-1;
          @include font-family-proxima();
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
          width: 80%;
        }

        span {
          color: $color-black-1;
          @include font-family-proxima();
          @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
          width: 20%;
          display: block;
          white-space: nowrap;
        }
      }

      .valueNameWrapper:last-child {
        border-bottom: none;
      }

      &Left {
        flex-basis: 50%;
        background: $color-white-3;
        padding: 60px;
        align-self: stretch;
      }

      &Right {
        flex-basis: 50%;
        padding: 60px;
        border: 1.25px solid $color-grey-2;
        background: $color-white-0;
        align-self: stretch;
      }
    }

    .bottomBox {
      padding: 60px;
      border: 1.25px solid $color-grey-2;
      background: $color-white-0;

      h1 {
        color: $color-black-1;
        @include font-family-miller();
        @include font-style($fs: 3.6rem, $fw: 500, $lh: 138%);
        margin-bottom: 12px;
      }

      .liWrapper {
        padding: 20px 0;
        border-bottom: 1px solid $color-grey-3;

        li {
          color: $color-black-1;
          @include font-family-proxima();
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
          text-indent: -23px;
          padding-left: 28px;
        }
      }

      .liWrapper:last-child {
        border-bottom: none;
      }
    }
  }
}

// theEssence
.theEssence {
  @include d-flex($jc: space-between, $al: stretch);

  &Details {
    background-color: $color-yellow-0;
    padding: 75px 68px 75px 120px;
    @include d-flex($jc: flex-start, $al: flex-start);
    flex-direction: column;
    gap: 40px;
    flex-basis: 50.5%;
    height: 400px;

    &Title {
      @include d-flex($al: flex-start !important);
      h2 {
        max-width: 335px;
      }
      p {
        color: $color-black-1 !important;
      }
    }
  }
  &Img {
    flex-basis: 49.5%;
    height: 400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  //   media queries
  @media (max-width: 992px) {
    &Details {
      padding: 80px 60px;
    }
  }

  @media (max-width: 768px) {
    &Details {
      padding: 80px 60px;
      height: 436px;

      &Title {
        text-align: left !important;

        h2 {
          width: 326px;
        }
      }
    }

    &Img {
      height: 436px;
    }
  }

  @media (max-width: 568px) {
    flex-direction: column;

    &Details {
      padding: 60px 20px;
      height: 336px;
      flex-basis: 0;

      &Title {
        gap: 8px;
        p {
          @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
        }
      }
    }

    &Img {
      flex-basis: 0;
      max-width: 100%;
    }
  }
}

// explore
.explore {
  padding: 40px 0;
  &Title {
    gap: 6px !important;
  }

  &Cocktails {
    padding: 60px 0;
    @include d-flex($jc: center, $al: center);
    gap: 21px;
  }

  &Btn {
    @include d-flex($jc: center, $al: center);
  }

  // media queries
  @media (max-width: 992px) {
    &Cocktails {
      flex-wrap: wrap;
      gap: 40px;
    }
  }

  @media (max-width: 568px) {
    &Title {
      gap: 16px !important;
    }

    &Btn {
      width: 100%;
      .link {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  //productInfo
  .productInfo {
    padding-top: 156px;

    &Container {
      padding: 0px 60px 60px 60px !important;
      flex-direction: column;
      gap: 32px;

      .detailBox {
        padding-bottom: 0;
        gap: 80px;
      }
    }
  }

  //ingredients
  .ingredient {
    &Container {
      .topBox {
        flex-direction: column;
        gap: 40px;
        align-items: stretch;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  //productInfo
  .productInfo {
    &Container {
      padding: 60px !important;
      padding-top: 0px !important;

      .detailBox {
        padding-bottom: 0;

        &Top {
          h1 {
            font-size: 3.6rem;
          }

          p {
            font-size: 1.8rem;
          }

          .headingBox {
            span {
              @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
            }
          }
        }
      }
    }
  }

  //ingredients
  .ingredient {
    &Container {
      padding: 60px !important;

      .topBox {
        flex-direction: column;
        gap: 40px;

        h1 {
          font-size: 3.6rem;
        }

        .valueNameWrapper {
          p {
            font-size: 1.8rem;
          }
        }

        &Left {
          padding: 60px;
        }

        &Right {
          padding: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .productInfo {
    &Container {
      padding: 0px 20px 60px 20px !important;

      .detailBox {
        padding-bottom: 0;
        &Top {
          h1 {
            font-size: 2.8rem;
          }
          p {
            font-size: 1.6rem;
          }
        }

        &Bottom {
          .nameBox {
            p {
              color: $color-grey-0;
              @include font-family-proxima();
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
            }

            h4 {
              color: $color-black-1;
              @include font-family-proxima();
              @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
            }
          }
        }
      }
    }
  }

  .ingredient {
    &Container {
      padding: 60px 20px !important;

      .topBox {
        h1 {
          font-size: 2.8rem;
        }

        .valueNameWrapper {
          p {
            font-size: 1.6rem;
          }
        }

        &Left {
          padding: 40px 20px;
        }
        &Right {
          padding: 40px 20px;
          h1 {
            margin-bottom: 32px;
          }
        }

        .valueNameWrapper {
          @include d-flex($jc: start, $al: start);
          padding: 16px 0;
          gap: 24px;
          border-bottom: 1px solid $color-grey-3;

          p {
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
          }
          span {
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
          }
        }
      }

      .bottomBox {
        padding: 40px 20px !important;

        h1 {
          font-size: 2.8rem;
        }

        .liWrapper {
          li {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
