@import "styles/variables.scss";

.header {
  background-color: $color-white-0;
  position: sticky;
  z-index: 999;
  top: -119px;

  &Container {
    padding: 0 20px !important;
    background-color: $color-white-0;
    max-width: 100% !important;

    .websiteLogo {
      cursor: pointer;
    }

    .headerImage {
      @include d-flex($jc: center, $al: center);
      padding: 12px 0 12px;
    }

    .sticky {
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      background: $color-white-0;
    }

    .navBarLinks {
      @include d-flex($jc: center, $al: center);
      gap: 60px;
      &Active {
        a {
          color: $color-black-1 !important;
        }
      }

      li {
        @include d-flex($jc: center, $al: center);
        gap: 0.6rem;
        cursor: pointer;
        // padding: 0 0 12px 0;
      }

      .navBarHoverCheck {
        &:hover {
          .navBarDropDownOption {
            transform: translateY(0) !important;
          }
          .rotateOnHover {
            transform: rotate(180deg);
          }
        }
      }

      // .navBarUnHover {
      //   &:hover {
      //     .navBarDropDownOption {
      //       transform: translateY(0) !important;
      //     }
      //     .rotateOnHover {
      //       transform: rotate(180deg);
      //     }
      //   }
      // }

      .navBarOption {
        padding: 12px 0;
        position: relative;
        color: $color-grey-0;
        @include font-family-proxima();
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 2.4rem);

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 10px;
          height: 1px;
          width: 0;
          background-color: $color-black-1;
          transition: width 0.4s;
        }

        &:hover::after {
          width: 100%;
        }
      }
      .navBarHover {
        img {
          transition: transform 0.3s ease;
        }
      }
      .navBarUnHover {
        img {
          transition: transform 0.3s ease;
        }
      }
      .navBarHover:hover .rotateOnHover {
        transform: rotate(180deg);
      }

      // .rotateOnHover:hover {
      //   transform: rotate(180deg);
      // }

      // & .rotateOnHover {
      //   transition: transform 0.3s ease;
      // }
    }
    .navBarOptionSpirits {
      @include d-flex($jc: center, $al: center);
      gap: 0.6rem;
    }
  }

  .tabHeaderContainer {
    position: fixed;
    width: 100%;
    top: 0;
    transition: all 0.5s ease-in-out;
    padding: 12px 20px 12px;
    background-color: $color-white-0;
  }

  .hidden {
    transform: translateY(-105%) !important;
    transition: all 0.5s ease-in-out;
  }

  .headerMobileContainer {
    @include d-flex($jc: space-between, $al: center);

    .crossButton {
      width: 32px;
    }
  }

  .navBarDropDownOption {
    @include d-flex($jc: center, $al: flex-start);
    background-color: $color-white-0;
    position: absolute;
    transition: all 0.6s ease-out;
    cursor: auto;
    z-index: -1;
    top: 178px !important;
    // bottom: 0;
    transform: translateY(-200%);
    padding: 40px;
    gap: 96px;
    left: 0;
    right: 0;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
    border-top: 0.4px solid $color-grey-2;

    &Sticky {
      top: 155px;
    }
    // &StickyActive {
    //   transform: translateY(0);
    // }

    .spirtsDropDown {
      max-width: 324px;
      width: 100%;
      flex-direction: column;
      @include d-flex($jc: space-between, $al: flex-start);
      gap: 76px;

      .spitsHeading {
        @include d-flex($jc: center, $al: flex-start);
        gap: 12px;

        p {
          @include font-family-proxima();
          @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.6px);
        }
      }

      .spitsDescription {
        @include d-flex($jc: space-between, $al: flex-start);
        @include font-family-proxima();
        gap: 6px;

        p {
          @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.32px);
          color: $color-grey-4;
        }

        h3 {
          color: $color-black-0;
          @include font-style($fs: 1.6rem, $fw: 500, $lh: 22.08px);
        }

        span {
          @include font-style($fs: 1.6rem, $fw: 500, $lh: 22.08px);
        }
      }
    }

    .navBarImageContainer {
      display: flex;
      gap: 40px;

      .navBarImages {
        display: flex;
        flex-direction: column;
        gap: 12px;
        cursor: pointer;
        overflow: hidden;

        &Img {
          overflow: hidden;
          max-width: 180px;

          img {
            display: block;
            height: auto;
            object-fit: cover;
            transition: all 0.5s ease;
            width: 100%;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        p {
          color: $color-black-0;
          @include font-style($fs: 1.6rem, $fw: 500, $lh: 22.4px);
          @include font-family-proxima();
          text-align: center;
        }
      }
    }
  }
}

.moveTop {
  .navBarMobile {
    top: 214px;
  }
  .navBarDropDown {
    top: 200px;
  }
}
.navBarDropDownActive {
  left: 0 !important;
}
.navBarDropDown {
  padding: 32px 48px 80px;
  max-width: 375px;
  position: absolute;
  background: white;
  width: 100%;
  top: 96px;
  left: -100%;
  transition: all 0.5s ease;

  .navBarLinksBackBtn {
    @include d-flex($jc: flex-start, $al: center);
    gap: 4px;
  }

  li {
    @include d-flex($jc: space-between, $al: center);
    padding: 16px 0 16px;
    border-bottom: 1px solid $color-grey-2;

    .downArrow {
      transform: rotate(-90deg);
      pointer-events: none;
    }

    .rightArrow {
      transform: rotate(90deg);
    }

    .leftArrow {
      transform: rotate(90deg);
    }

    .navBarOptionMobile {
      color: $color-grey-0;
      @include font-family-proxima();
      @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
    }

    .navBarGoBack {
      color: $color-black-1;
    }
  }
}

.navBarMobile {
  max-width: 100%;
  padding: 32px 20px 80px;
  position: absolute;
  background: white;
  width: 100%;
  top: 96px;
}

@media only screen and (max-width: 1024px) {
  .navBarDropDownOption {
    .spirtsDropDown {
      max-width: 324px !important;
      width: 100%;

      .spitsHeading {
        width: 100%;
        min-width: 324px;

        p {
          @include font-style($fw: 400 !important);
        }
      }
    }
    .navBarImageContainer {
      gap: 16px !important;
    }
  }
}
