@import "styles/variables.scss";

.inputContainer {
  @include d-flex($jc: start, $al: start);
  flex-direction: column;
  gap: 6px;
  width: 100%;

  span {
    display: block;
    width: 100%;
    color: $color-black-1;
    @include font-family-proxima();
    @include font-style($fs: 1.6rem, $fw: 500, $lh: 140%);
  }

  input {
    padding: 10px;
    border: none;
    width: 100%;
    border: 1px solid $color-grey-3;
    background: $color-white-0;
    @include font-family-proxima();
    @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);

    &:focus {
      outline: 1px solid $color-black-1;
    }

    &::placeholder {
      color: $color-grey-0;
      @include font-family-proxima();
      @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
    }
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Hide Safari autofill icon */
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    display: none !important;
    visibility: hidden !important;
  }
}
