@import "styles/variables.scss";

.cookieMain {
  width: 100%;
  position: fixed;
  bottom: 0;
  transform: translateY(101%);
  transition: 0.3s linear;
  z-index: 20;

  .cookie {
    max-width: 1200px;
    padding: 24px;
    margin: 0 auto;
    @include d-flex($jc: space-between, $al: center);
    background-color: white;

    &Left {
      @include d-flex($al: center);
      gap: 24px;
      flex-basis: 54.8333%;

      .imgBox {
        min-width: 36px;
        height: 36px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      p {
        color: $color-black-1;
        @include font-family-proxima();
        @include font-style(1.6rem, 400, 140%);

        span {
          color: #0031e0;
          @include font-family-proxima();
          @include font-style(1.8rem, 500, 140%);
          text-align: center;
          text-decoration-line: underline;
        }
      }

      .readMore {
        color: #0031e0;
        @include font-family-proxima();
        @include font-style(1.6rem, 500, 120%);
        cursor: pointer;
        text-decoration-line: underline;
      }
    }

    &Right {
      @include d-flex($al: center, $jc: flex-end);
      gap: 24px;
      flex-basis: 36%;

      button {
        @include d-flex();
        padding: 10px 20px;
        outline: none;
        @include font-family-proxima();
        @include font-style(1.8rem, 500, 140%);
        cursor: pointer;
        height: 45px;
        white-space: nowrap;
      }

      .accept {
        background-color: $color-black-1;
        color: $color-white-0;
        border: none;
        padding: 10px;
      }

      .manage {
        color: $color-black-1;
        background-color: $color-white-0;
        border: 1px solid $color-black-1;
        align-items: center;
      }
    }
  }
}

.cookieMainShow {
  transform: translateY(0%);
}

@media screen and (max-width: 768px) {
  .cookieMain {
    .cookie {
      max-width: 768px;

      &Left {
        flex-basis: 45.7%;
      }

      &Right {
        flex-basis: 47.3958%;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .cookieMain {
    .cookie {
      max-width: 568px;
      flex-direction: column;
      gap: 32px;
      border-top: 1px solid rgb(0, 0, 0, 0.25);

      &Left {
        flex-direction: column;

        p {
          @include font-style(1.6rem, 500, 140%);
        }
      }

      &Right {
        flex-direction: column;
        width: 100%;
        gap: 16px;

        button {
          width: 100%;
          justify-content: center;
          padding: 12px 0 12px;
          height: 60px;
        }

        .accept {
          padding: 12px 0 12px;
          height: 60px;
          align-items: center;
        }
      }
    }
  }
}
