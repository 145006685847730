@import "styles/variables.scss";

.privacyPolicy {
  padding: 80px 20px;

  &Container {
    max-width: 780px;
    margin: 0 auto;

    .commonHeading {
      margin-bottom: 60px;

      p {
        font-size: 16px;
      }
    }
  }

  &SpecialList {
    margin-bottom: 10px;
    list-style: disc !important;
    padding: 0 0 0 26px;

    &:last-child {
      margin: 0;
    }
    li {
      &::marker {
        color: $color-grey-0 !important;
      }
    }
  }

  &List {
    margin-bottom: 10px;
    list-style: disc !important;
    padding: 0 0 0 26px;

    &:last-child {
      margin: 0;
    }
  }

  &Contents {
    @include d-flex();
    flex-direction: column;
    gap: 40px;
  }
}

.commonPolicy {
  margin-bottom: 40px;

  &:last-child {
    margin: 0;
  }

  &Heading {
    color: $color-black-1;
    @include font-style(2.8rem, 500, 138%);
    @include font-family-miller();
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  &Para {
    color: $color-grey-0;
    @include font-style(1.6rem, 400, 140%);
    @include font-family-proxima();
  }
}

.data {
  font-weight: 500 !important;
  color: $color-black-1 !important;
}

.subHeading {
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .privacyPolicy {
    padding-top: 96px;

    &Container {
      padding: 80px 40px;
    }
  }
}

@media screen and (max-width: 568px) {
  .privacyPolicy {
    padding-top: 96px;
    padding: 0px 20px;

    &Container {
      padding: 60px 0;
      padding-top: 156px;
    }

    .commonHeading {
      .commonPolicyPara {
        text-align: left;
      }
    }
  }
}
