@import "styles/variables.scss";

.cookieModalBox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(1, 1, 1, 0.5);
  z-index: 1000;
  @include d-flex($jc: center, $al: center);

  .modal {
    background-color: $color-white-0;
    padding: 56px;
    @include d-flex($jc: center, $al: center);
    flex-direction: column;
    gap: 32px;
    position: relative;

    &Top {
      width: 481px;
      @include d-flex($jc: center, $al: center);
      flex-direction: column;
      gap: 8px;

      h1 {
        color: $color-black-1;
        text-align: center;
        @include font-family-proxima();
        @include font-style(2.8rem, 500, 140%);
        line-height: 140%;
      }

      p {
        color: $color-black-1;
        text-align: center;
        @include font-family-proxima();
        @include font-style(1.6rem, 400, 140%);
        margin-top: 16px;
      }

      span {
        color: #0031e0;
        @include font-family-proxima();
        @include font-style(1.8rem, 400, 140%);
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    &Middle {
      @include d-flex($al: center);
      flex-direction: column;
      gap: 24px;
      width: 481px;

      .toggleBox {
        width: 100%;
        @include d-flex($jc: space-between, $al: center);

        p {
          color: $color-black-1;
          @include font-family-proxima();
          @include font-style(2rem, 500, 140%);
        }
      }
    }

    button {
      width: 100%;
      @include d-flex($jc: center);
      padding: 10px 20px;
      outline: none;
      @include font-family-proxima();
      @include font-style(1.8rem, 500, 140%);
      cursor: pointer;
      height: 45px;
      background-color: $color-black-1;
      color: $color-white-0;
      border: none;
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 568px) {
  .cookieModalBox {
    .modal {
      // min-width: 568px;
      padding: 36px 20px 20px;

      &Top {
        max-width: 295px;

        h1 {
          @include font-style(2.4rem, 500, 140%);
          margin-top: 32px;
        }
      }

      &Middle {
        max-width: 295px;

        .toggleBox {
          width: 100%;

          p {
            @include font-style(1.8rem, 500, 140%);
          }
        }
      }
    }
  }
}
