@import "styles/variables.scss";

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #000000 94%, #0000) top/3.8px 3.8px no-repeat,
    conic-gradient(#0000 30%, #000000);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 3.8px),
    #000 0
  );
  animation: spinner-c7wet2 1s infinite linear;
}

.visitSpinner {
  background-color: white;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

.subscriptionMessage {
  color: #0bc64a;
  @include font-family-proxima();
  @include font-style(1.2rem, 500, 140%);
  position: absolute;
  bottom: -27px;
  @include d-flex(flex-start, center);
  gap: 8px;

  img {
    width: 16px;
    height: 16px;
  }
}

.emailBox {
  position: relative;
  h3 {
    color: $color-white-0;
    @include font-family-miller();
    @include font-style($fs: 2.8rem, $fw: 500, $lh: 138%);
    text-transform: capitalize;
    margin-bottom: 32px;
  }

  p {
    color: $color-white-0;
    @include font-family-proxima;
    @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
    margin-bottom: 16px;
    opacity: 0.7;
  }

  .emailSubscribeBox {
    display: flex;
    border: none;
    justify-content: space-between;
    position: relative;

    input {
      width: 71%;
      padding: 8px 0;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      outline: none;
      color: $color-white-0;
      @include font-family-proxima();
      @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);

      &::placeholder {
        color: $color-white-0;
        @include font-family-proxima();
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        opacity: 0.4;
      }
    }

    .emailError {
      position: absolute;
      @include font-family-proxima();
      @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
      bottom: -24px;
      color: red;
    }

    .emailSuccess {
      position: absolute;
      @include font-family-proxima();
      @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
      bottom: -24px;
    }
  }
}

.color {
  h3 {
    color: $color-black-1;
  }
  p {
    color: $color-black-1;
  }

  .emailSubscribeBox {
    input {
      color: $color-black-1;
      border-bottom: 1px solid $color-black-1;
    }

    input::placeholder {
      color: $color-black-1 !important;
    }
  }
}

.visitBox {
  position: relative;
  width: 100%;
  h3 {
    color: $color-black-1;
    font-size: 20px;
    @include font-family-proxima();
    font-style: normal;
  }
  p {
    color: $color-black-1;
    font-size: 16px;
  }

  .emailSubscribeBox {
    input {
      width: 100%;
      border-bottom: 1px solid $color-grey-0;
      color: $color-black-1;
      @include font-family-proxima();
      @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);

      &::placeholder {
        color: $color-grey-0;
        opacity: 1;
        font-size: 16px;
        opacity: 0.6;
      }
    }

    .emailError {
      top: 42px;
    }

    .emailSuccess {
      bottom: 48px !important;
    }
  }
}

@media screen and (max-width: 568px) {
  .visitBox {
    width: 100%;
    h3 {
      font-size: 20px !important;
    }
    p {
      font-size: 14px !important;
    }
    .emailSubscribeBox {
      .emailSuccess {
        bottom: 38px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .spinner {
    width: 15px;
    height: 15px;
  }

  .emailBox {
    .emailSubscribeBox {
      gap: 24px;

      input {
        width: 100%;
      }

      button {
        width: max-content;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .emailBox {
    .emailSubscribeBox {
      flex-direction: column;
      gap: 32px;

      .emailError {
        bottom: 54px;
      }

      .emailSuccess {
        bottom: 54px;
      }
    }
    p {
      margin-bottom: 32px;
    }

    h3 {
      @include font-style($fs: 2.4rem, $fw: 500, $lh: 138%);
    }
  }
}
