@import "styles/variables.scss";

.detailsNextLine {
  display: inline-block;
}

.detailProductContainer {
  padding: 60px 20px !important;
  @include d-flex($jc: flex-end);
  gap: 60px;

  .mainImage {
    max-width: 450px;

    img {
      width: 100%;
    }
  }

  .imageContainer {
    @include d-flex();
    gap: 24px;

    .sideImageContainer {
      min-width: 100px;
      @include d-flex();
      flex-direction: column;
      gap: 16px;

      &Logo {
        max-width: 100px;
        object-fit: cover;
      }

      img {
        cursor: pointer;
        opacity: 0.5;
      }

      .active {
        opacity: 1;
        border: 2px solid $color-black-0;
      }
    }
  }
  .productDetails {
    @include d-flex();
    flex-direction: column;
    gap: 32px;
    max-width: 566px;

    .productHeading {
      width: 100%;
      @include d-flex($jc: space-between, $al: center);

      .productName {
        @include d-flex();
        flex-direction: column;
        gap: 8px;

        h3 {
          color: $color-black-1;
          @include font-style($fs: 3.6rem, $fw: 500, $lh: 49.68px);
          @include font-family-miller();
          text-transform: capitalize;
        }

        p {
          color: $color-grey-0;
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
          @include font-family-proxima();
        }
      }
      .productRate {
        h3 {
          color: $color-black-1;
          @include font-style($fs: 3.6rem, $fw: 500, $lh: 49.68px);
          @include font-family-proxima();
        }
      }
    }
    .descriptionWrap {
      @include d-flex();
      flex-direction: column;
      gap: 40px;

      .productDescription {
        min-width: 566px;
        width: 100%;

        p {
          color: $color-grey-0;
          @include font-style($fs: 1.8rem, $fw: 400, $lh: 25.2px);
          @include font-family-proxima();
        }

        .readMore {
          cursor: pointer;
          @include font-style($fs: 1.8rem, $fw: 500, $lh: 25.2px);
          @include font-family-proxima();
          color: $color-blue-0;
          text-decoration: underline;
          margin-left: 5px;
        }
      }

      .flowerButtonWrap {
        width: 100%;
        @include d-flex();
        gap: 60px;
        flex-direction: column;

        .productFlavourSection {
          width: 100%;
          @include d-flex($jc: space-between);
          .productFlavour {
            @include d-flex($jc: space-between, $al: center);
            gap: 8px;

            p {
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
              @include font-family-proxima();
            }
          }
        }

        Button {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .productDescription {
    min-width: 300px !important;

    ul {
      @include d-flex();
      flex-direction: column;
      gap: 32px;
    }
  }

  .detailsNextLine {
    display: inline-block;
  }
}

@media only screen and (max-width: 992px) {
  .detailProductContainer {
    padding: 156px 60px 60px !important;

    .tabContainer {
      @include d-flex();
      flex-direction: column;
      gap: 60px;

      .tabImageContainer {
        @include d-flex($jc: space-between);
        gap: 40px;

        .imageContainer {
          @include d-flex();
          flex-direction: column;
          width: 100%;

          .mainImage {
            max-width: 100%;
          }

          .sideImageContainer {
            @include d-flex($jc: flex-start);
            flex-direction: row;
            width: 304px;
            gap: 10px;
            width: 100%;

            img {
              height: 68px;
              min-width: 68px;
            }
          }
        }
        .tabProductName {
          max-width: 304px;
          width: 100%;
          @include d-flex();
          flex-direction: column;
          gap: 32px;

          .productType {
            h3 {
              @include font-style($fs: 3.6rem, $fw: 500, $lh: 49.68px);
              @include font-family-miller();
            }

            p {
              color: $color-grey-0;
              @include font-style($fs: 1.6rem, $fw: 400, $lh: 22.4px);
              @include font-family-proxima();
            }
          }

          h4 {
            @include font-style($fs: 3.6rem, $fw: 500, $lh: 49.68px);
            @include font-family-proxima();
          }
        }
      }
      .tabProductDetails {
        @include d-flex();
        flex-direction: column;
        gap: 40px;
        width: 100%;

        .productFlavourSection {
          @include d-flex($jc: space-between);
          width: 100%;

          .productFlavour {
            @include d-flex($jc: space-between, $al: center);
            gap: 8px;

            p {
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.6px);
              @include font-family-proxima();
            }
          }
        }

        p {
          color: $color-grey-0;
          @include font-family-proxima();
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 19.6px);
        }

        .readMore {
          cursor: pointer;
          @include font-family-proxima();
          @include font-style($fs: 1.6rem, $fw: 500, $lh: 19.6px);
          color: $color-blue-0;
          text-decoration: underline;
          margin-left: 5px;
        }
      }
    }

    ul {
      @include d-flex();
      flex-direction: column;
      gap: 32px;
    }
  }
  .mainImage {
    // object-fit: cover;
    // min-height: 304px !important;
    // min-width: 304px !important;
    // overflow: hidden;

    // img {
    //   width: 100%;
    // }
  }
  .detailsNextLine {
    display: block;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 558px) {
  .detailProductContainer {
    margin-top: 0 !important;
    padding: 60px 20px !important;
    padding-top: 156px !important;

    .tabContainer {
      .tabImageContainer {
        flex-direction: column;
        gap: 60px;
        width: 100%;

        .imageContainer {
          .sideImageContainer {
            gap: 8px;
            img {
              min-width: 78px;
              height: 78px;
            }
          }
        }
        .tabProductName {
          max-width: 100%;
          gap: 24px;

          .productType {
            @include d-flex();
            flex-direction: column;
            gap: 8px;

            h3 {
              @include font-style($fs: 2.8rem, $fw: 500, $lh: 38.4px);
              @include font-family-miller();
            }

            p {
              color: $color-grey-0;
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.6px);
            }
          }

          h4 {
            @include font-style($fs: 3.2rem, $fw: 500, $lh: 49.68px);
            @include font-family-proxima();
          }

          Button {
            width: 100%;
          }
        }
      }
      .tabProductDetails {
        gap: 32px;

        .productFlavourSection {
          flex-wrap: wrap;
          gap: 12px;

          .productFlavour {
            @include d-flex($jc: flex-start, $al: center);
            gap: 8px;
            max-width: 147px;
            width: 100%;

            p {
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.6px);
              @include font-family-proxima();
            }
          }
        }
        .tabProductDescription {
          p,
          span {
            color: $color-grey-0;
            @include font-family-proxima();
            @include font-style($fs: 1.6rem, $fw: 400, $lh: 19.6px);
          }
        }
      }
    }
    // .mainImage {
    //   min-width: 100%;
    //   min-height: 390px;
    //   height: 100%;
    //   max-width: 335px !important;
    //   min-width: 100% !important;

    //   img {
    //     width: 100%;
    //   }
    // }
  }
}
