@import "styles/variables.scss";

.readyToDrink {
  &Container {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    span {
      padding: 8px 16px;
      background: #ffeabf;
      color: $color-black-1;
      @include font-family-proxima();
      @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
      text-transform: uppercase;
    }
  }
  .container {
    padding: 60px 20px;
  }

  &Boxes {
    padding: 140px 0 0px;
    @include d-flex($jc: center, $al: center);
    gap: 80px;
    flex-wrap: wrap;

    &Wrapper {
      position: relative;
      border-radius: 150px 150px 0px 0px;
      border: 1px solid $color-grey-3;
      background: $color-white-0;
      flex-basis: 25%;
      height: 375px;

      &::after {
        content: "";
        position: absolute;
        height: 122%;
        width: 175px;
        background-size: 100% 100%;
        top: -70px;
        left: calc(50% - 87px);
        transition: all 0.4s linear;
      }

      &:first-child {
        &::after {
          background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/canPaani.webp");
        }
      }

      &:nth-child(2) {
        &::after {
          background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/canJamun.webp");
        }
      }

      &:last-child {
        &::after {
          background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/canBael.webp");
        }
      }

      &:hover {
        &::after {
          transform: scale(0.7);
          transform-origin: top;
        }

        .readyToDrinkBoxesContent {
          &::before {
            top: -50%;
          }
          h4 {
            opacity: 1;
          }

          p {
            opacity: 1;
          }
        }
      }
    }

    &Content {
      height: 100%;
      width: 100%;
      position: relative;
      border-radius: 150px 150px 0px 0px;
      overflow: hidden;
      @include d-flex($jc: flex-end, $al: flex-start);
      flex-direction: column;
      padding: 32px;
      gap: 10px;

      h4 {
        color: $color-black-1;
        @include font-family-proxima();
        @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
        opacity: 0;
        transition: all 0.4s linear;
        margin-bottom: 10px;
      }

      p {
        color: $color-grey-0;
        @include font-family-proxima();
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        opacity: 0;
        transition: all 0.4s linear;
      }

      &::before {
        content: "";
        position: absolute;
        background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/home/cardBgLines.webp");
        background-size: 100% 100%;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transition: all 0.4s linear;
      }
    }
  }

  &Description {
    @include font-family-proxima();
    color: $color-grey-0;
    @include font-style($fs: 1.8rem, $fw: 400, $lh: 140%);
    text-align: center;
    max-width: 992px;
    margin: 60px auto 0;
  }
}

@media (max-width: 1024px) {
  .readyToDrinkBoxesContent {
    padding: 22px 22px;
  }
}

// media queries
@media (max-width: 992px) {
  .readyToDrink {
    &Container {
      h2 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
    &Boxes {
      gap: 86px 48px;

      &Wrapper {
        flex-basis: 42%;
        &::after {
          transform: scale(0.7);
          transform-origin: top;
        }
      }

      &Content {
        &::before {
          top: -50%;
        }

        h4,
        p {
          opacity: 1;
        }
      }
    }

    .readyToDrinkBoxesContent {
      padding: 32px;
    }
  }
}

@media (max-width: 768px) {
  .readyToDrink {
    &Container {
      text-align: center;
    }

    &Boxes {
      // flex-direction: column;
      flex-wrap: wrap;

      &Wrapper {
        min-height: 375px;
        max-width: 300px;
        @include d-flex($jc: flex-end, $al: flex-end);
      }

      &Content {
        height: 375px;
      }
    }
  }
}

@media (max-width: 568px) {
  .readyToDrink {
    &Container {
      span {
        @include font-style($fs: 1.2rem, $fw: 400, $lh: 140%);
      }
    }
    &Boxes {
      padding-bottom: 0;
      flex-direction: column;
      gap: 132px;
    }
  }
}
