@import "styles/variables.scss";

.ourProduct {
  padding: 60px 20px !important;

  .commonHeading {
    margin-bottom: 60px;
  }

  &List {
    max-width: 270px;
    @include d-flex();
    flex-direction: column;
    gap: 16px;

    &Img {
      overflow: hidden;

      img {
        display: block;
        height: auto;
        object-fit: cover;
        transition: all 0.5s ease;
        width: 100%;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &Data {
      &Owner {
        @include d-flex(space-between, center);
        margin-bottom: 16px;

        &Name {
          h3 {
            color: $color-black-1;
            @include font-style(1.8rem, 600, 140%);
            @include font-family-proxima();
            margin-bottom: 4px;
          }

          span {
            color: $color-grey-0;
            @include font-style(1.2rem, 400, 140%);
            @include font-family-proxima();
            text-transform: uppercase;
          }
        }

        &Count {
          color: $color-black-1;
          @include font-style(2rem, 600, 140%);
          @include font-family-proxima();
        }
      }

      &Para {
        color: $color-grey-0;
        @include font-style(1.6rem, 400, 140%);
        @include font-family-proxima();
        margin-bottom: 16px;
      }

      &Button {
        border: 1px solid $color-grey-8;
        padding: 10px 20px;
        width: 100%;
        background: transparent;
        outline: none;
        color: $color-black-1;
        @include font-style(1.8rem, 500, 140%);
        @include font-family-proxima();
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }
      .ourProductListDataButton:hover {
        background-color: $color-black-1;
        color: $color-white-0;
      }
    }
  }

  .mapData {
    @include d-flex(center, center);
    gap: 40px;
  }

  // responsive
  @media (max-width: 992px) {
    padding: 60px !important;
    .mapData {
      flex-wrap: wrap;
      gap: 40px;
      justify-content: center;
    }

    &List {
      max-width: 304px;
    }
  }

  @media (max-width: 568px) {
    padding: 60px 20px !important;
    .mapData {
      flex-direction: column;
    }

    &List {
      max-width: 100%;
    }
    .commonHeading {
      margin-bottom: 40px;
    }
  }
}
