@import "styles/variables.scss";

.notFoundContainer {
  @include d-flex($jc: center, $al: center);
  flex-direction: column;
  gap: 49px;
  height: 100vh;

  .notFoundContent {
    max-width: 412px;
    @include d-flex($al: center);
    flex-direction: column;
    gap: 16px;

    h4 {
      color: $color-grey-4;
      text-align: center;
      @include font-style($fs: 1.8rem, $fw: 500, $lh: 140%);
      @include font-family-proxima();
    }

    p {
      color: $color-grey-4;
      text-align: center;
      @include font-family-proxima();
      @include font-style($fs: 1.4rem, $fw: 400, $lh: 140%);
    }
  }
}
