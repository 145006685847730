@import "styles/variables.scss";

.kNfncX {
  color: $color-black-1;
  @include font-family-proxima();
  @include font-style(1.6rem, 500, 140%);
}

.jHuwCI {
  margin-bottom: 20px !important;
}

.gpCFLa {
  margin: 0 !important;
}

.dropdown {
  position: absolute !important;
  z-index: 99;
  top: 100px;
}

.hOLLYl {
  padding: 16px !important;
  height: 220px !important;
  overflow-y: scroll !important;
  position: relative;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.es-review-content-text {
  color: $color-black-1;
  @include font-family-proxima();
  @include font-style(1.4rem, 400, 140%);
}

.mapboxgl-popup-close-button {
  &:hover {
    background: none !important;
  }
}

.mapboxgl-popup-content {
  position: relative;

  button {
    height: 40px;
    cursor: pointer;
    outline: none;
    padding: 6px 12px;
    border-radius: 0;
    transition: all 0.4s ease;
    @include d-flex(center, center);
    gap: 10px;
    background: transparent;

    img {
      transition: all 0.4s ease;

      &:hover {
        background: none !important;
        transform: translateX(10px);
      }
    }
  }

  .getDirectionButton {
    outline: none;
    background: $color-black-1;
    border: 1px solid $color-black-1;
    padding: 10px 20px;
    color: $color-white-0;
    @include font-style(1.8rem, 500, 140%);
    @include font-family-proxima();
    @include d-flex(center, center);
    gap: 10px;
    cursor: pointer;

    img {
      transition: all 0.4s ease;
    }

    &:hover {
      img {
        transform: translateX(5px);
        transition: all 0.4s ease;
      }
    }

    background-color: $color-white-0;
    color: $color-black-1;
  }
}

.visitUs {
  padding: 60px 0;

  &Heading {
    align-items: flex-start !important;
  }

  &Map {
    padding-top: 40px;
    @include d-flex($jc: space-between, $al: flex-start);
    gap: 40px;
    position: relative;

    &Search {
      position: relative;
      flex-basis: 28.4%;

      img {
        position: absolute;
        left: 20px;
        top: 16px;
      }

      input {
        padding: 16px 20px 16px 53px;
        width: 100%;
        color: $color-grey-0;
        border: 1px solid $color-grey-9;
        background: $color-white-0;
        @include font-family-proxima();
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        -webkit-appearance: none;
        cursor: pointer;
      }
    }

    &Place {
      border: 1px solid $color-grey-2;
      margin-top: 20px;

      p {
        padding: 12px;
        border-bottom: 1px solid $color-grey-2;
        background: #fff;
        color: $color-black-1;
        @include font-family-proxima();
        @include font-style(1.4rem, 400, 140%);
        cursor: pointer;
      }

      &NoData {
        @include font-family-proxima();
        @include font-style(1.4rem, 400, 140%);
        color: $color-grey-0;
        margin-top: 20px;
        border: 1px solid var(--Gray-100, #edf0f2);
        background: #fff;
        padding: 12px 20px;
      }
    }

    &Section {
      position: relative;
      flex-basis: 68.4%;
      height: 511px;
      .mapboxgl-marker {
        cursor: pointer;
      }
      .mapboxgl-map {
        .mapboxgl-interactive {
          .custom-marker {
            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  .mapboxgl-popup-content {
    @include d-flex();
    gap: 32px;
    flex-direction: column;
    padding: 20px;
    width: 340px;

    .headingContainer {
      @include d-flex();
      flex-direction: column;
      gap: 6px;

      h3 {
        @include font-style($fs: 1.6rem, $fw: 500, $lh: 22.4px);
        @include font-family-proxima();
      }

      p {
        @include font-style($fs: 1.4rem, $fw: 500, $lh: 22.4px);
        @include font-family-proxima();
        color: $color-grey-0;
      }
    }

    .mapPopupContainer {
      @include d-flex();
      flex-direction: column;
      gap: 8px;

      .timeContainer {
        @include d-flex();
        width: 300px;
        gap: 12px;

        p {
          @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.4px);
          @include font-family-proxima();
          color: $color-black-1;
        }
      }
    }

    div {
      @include d-flex($jc: center, $al: center);
    }
  }

  //   media queries
  @media (max-width: 992px) {
    padding: 40px;
    margin-top: 96px;

    &Map {
      gap: 20px;

      &Search {
        flex-basis: 100%;
        max-width: 258px;
      }

      &Section {
        height: 471px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 96px 0 0;
    margin-top: 0;

    .mapboxgl-popup-content {
      max-width: 279px;
      max-height: 252px;
      right: 19px;
      gap: 12px;

      .mapPopupContainer {
        .timeContainer {
          max-width: 239px !important;
          width: 100%;
        }
      }

      .headingContainer {
        h3 {
          @include font-style($fs: 1.4rem, $fw: 500, $lh: 19.2px);
        }
        p {
          @include font-style($fs: 1.2rem, $fw: 500, $lh: 19.2px);
        }
      }

      .getDirectionButton {
        @include font-style($fs: 1.4rem, $fw: 500, $lh: 19.2px);
      }
    }

    .container {
      padding: 60px;

      .visitUsHeading {
        @include d-flex();
        max-width: 504px;
        gap: 16px;
      }
    }

    &Map {
      &Search {
        max-width: 100%;
      }

      &Section {
        flex-basis: 100%;
      }
    }

    .mapboxgl-canvas {
      width: 375px;
      height: 100%;
    }
  }
  @media (max-width: 568px) {
    padding: 96px 0 0 0;

    &Map {
      flex-wrap: wrap;
      justify-content: center;

      &Section {
        flex-basis: 100%;
      }

      .visitUsMapSearch {
        max-width: 307px !important;

        .mapScrollContainer {
          max-width: 335px;
          height: 432px;
          overflow: scroll;
          .suggestionPlace {
            width: 100% !important;
            max-width: 307px !important;
          }
        }
      }
    }

    .container {
      padding: 60px 20px;

      .visitUsHeading {
        @include d-flex();
        max-width: 335px;
        gap: 16px;

        h2 {
          max-width: 204px;
        }

        p {
          @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.6px);
        }
      }
    }

    .mapboxgl-map {
      width: 100%;
    }

    .visitUsHeading {
      text-align: left;
    }
  }
}

// mapfilter

.visitUs {
  .container {
    .visitUsMap {
      .visitUsMapSearch {
        max-width: 340px;
        //   overflow-x: hidden;
        max-height: 511px;
        //   overflow: scroll;

        .inputSearch {
          margin-bottom: 20px;
          border: none;
          outline: none;
          position: sticky;
          background: $color-white-0;
          z-index: 9;
          top: 0;

          input {
            outline: none;
          }

          input:focus {
            border: 1px solid $color-black-1;
          }
        }
        .mapScrollContainer {
          @include d-flex();
          flex-direction: column;
          gap: 16px;
          background-color: $color-white-3;
          padding: 10px;
          max-height: 435px;
          overflow: scroll;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: block;
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: $color-grey-4;
          }

          .suggestionPlace {
            border: 1px solid $color-grey-2;
            @include d-flex();
            gap: 32px;
            flex-direction: column;
            padding: 20px;
            width: 100%;
            background-color: $color-white-0;

            h3 {
              @include font-style($fs: 1.6rem, $fw: 500, $lh: 22.4px);
              @include font-family-proxima();
            }

            .address {
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.4px);
              @include font-family-proxima();
              color: #606e80;
            }
            p {
              @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.4px);
              @include font-family-proxima();
            }

            .headingContainer {
              @include d-flex();
              flex-direction: column;
              gap: 6px;

              h3 {
                @include font-style($fs: 1.6rem, $fw: 500, $lh: 22.4px);
                @include font-family-proxima();
              }
            }
            .mapPopupContainer {
              @include d-flex();
              flex-direction: column;
              gap: 8px;
              max-width: 258px;

              .timeContainer {
                @include d-flex();
                gap: 12px;

                img {
                  position: static;
                }

                p {
                  @include font-style($fs: 1.4rem, $fw: 400, $lh: 19.4px);
                  @include font-family-proxima();
                }
              }
            }
            .getDirectionButton {
              outline: none;
              background: $color-black-1;
              border: 1px solid $color-black-1;
              padding: 10px 20px;
              color: $color-white-0;
              @include font-style(1.8rem, 500, 140%);
              @include font-family-proxima();
              @include d-flex(center, center);
              gap: 10px;
              cursor: pointer;

              img {
                position: static;
                transition: all 0.4s ease;
              }

              &:hover {
                img {
                  transform: translateX(5px);
                  transition: all 0.4s ease;
                }
              }

              background-color: $color-white-0;
              color: $color-black-1;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      .visitUsMap {
        .visitUsMapSearch {
          flex-basis: 69.8%;
          //  height: 471px;

          img {
            top: 18px;
          }

          input {
            padding: 16px 0 16px 53px;
          }

          .mapScrollContainer {
            height: 386px;
          }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .container {
      .visitUsMap {
        .visitUsMapSearch {
          flex-basis: 100%;

          input {
            padding-left: 50px;
          }

          .mapScrollContainer {
            .suggestionPlace {
              min-width: 100%;
            }
          }
        }
      }
    }
  }
}

.visitUsMapSearch::-webkit-scrollbar {
  display: none;
}

// .visitUs {
//   &Container {
//     padding: 60px 20px !important;
//     &Top {
//       margin-bottom: 40px;
//       h1 {
//         color: $color-black-1;
//         @include font-family-miller();
//         @include font-style(3.6rem, 500, 138%);
//         font-style: italic;
//         text-transform: capitalize;
//         margin-bottom: 16px;
//       }

//       p {
//         color: var(--Text-txt-secondary, #606e80);
//         @include font-family-proxima();
//         @include font-style(1.8rem, 400, 140%);
//       }
//     }
//     &Bottom {
//       @include d-flex();
//       gap: 40px;

//       .subscribeBox {
//         width: 340px;
//       }

//       .mapImageBox {
//         max-width: 820px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 768px) {
//   .visitUs {
//     padding-top: 96px;

//     &Container {
//       padding: 60px !important;

//       &Bottom {
//         gap: 20px;
//         //   height: 470px;

//         .subscribeBox {
//           max-width: 258px !important;
//         }

//         .mapImageBox {
//           width: 375px !important;
//           height: 470px;

//           img {
//             height: 100%;
//             width: 100%;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 568px) {
//   .visitUs {
//     &Container {
//       padding: 60px 20px !important;

//       &Top {
//         margin-bottom: 40px;
//         h1 {
//           @include font-style(2.8rem, 600, 138%);
//         }

//         p {
//           @include font-style(1.6rem, 400, 140%);
//         }
//       }

//       &Bottom {
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 40px;

//         .subscribeBox {
//           max-width: 375px !important;
//         }

//         .mapImageBox {
//           width: 100% !important;
//           height: auto !important;

//           img {
//             height: 100%;
//             width: 100%;
//           }
//         }
//       }
//     }
//   }
// }
