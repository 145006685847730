.parent {
  .imageWrapper {
    margin: 4px 10px;
    max-height: 240px;
    max-width: 240px;
    object-fit: cover;

    img {
      height: 240px;
      width: 240px;
    }
  }
}
