@import "styles/variables.scss";

// cocktailsList
.cocktailsList {
  &Titles {
    border-bottom: 1px solid $color-grey-7;

    ul {
      @include d-flex($jc: space-between, $al: center);
      padding: 24px 0;
      max-width: 800px;
      margin: 0 auto;

      li {
        color: $color-grey-0;
        @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        @include font-family-proxima();
        text-transform: uppercase;
        cursor: pointer;
      }

      .cardTitleActive {
        position: relative;
        padding-bottom: 4px;
        color: $color-black-1;

        &::after {
          content: "";
          position: absolute;
          background-color: $color-black-1;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  &Section {
    padding: 60px 0;
    @include d-flex($jc: flex-start, $al: center);
    flex-wrap: wrap;
    gap: 20px;

    .noFound {
      width: 100%;
      text-align: center;
      color: $color-black-1;
      @include font-style($fs: 3.6rem, $fw: 500, $lh: 138%);
      @include font-family-miller();
      text-transform: capitalize;
    }
  }

  .container {
    padding-top: 0;
    padding-bottom: 0px;

    .buttonBox {
      margin-top: 60px;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 60px;

      button {
        border: 1px solid $color-black-1;
      }
    }
  }

  //   media queries
  @media (max-width: 992px) {
    &Titles {
      ul {
        padding: 24px 60px;
      }
    }
  }

  @media (max-width: 768px) {
    &Section {
      justify-content: flex-start;
      gap: 40px;
      margin: 0 auto;
      max-width: 649px;
    }

    &Titles {
      ul {
        padding: 32px 0;
        gap: 60px;
        max-width: 648px;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  @media (max-width: 568px) {
    &Section {
      justify-content: center;
    }

    .container {
      padding: 0 20px 0px 20px;

      .buttonBox {
        width: 100%;

        button {
          border: 1px solid $color-black-1;
          width: 100%;
        }
      }
    }
    &Titles {
      ul {
        padding: 16px 20px;
      }
    }
  }
}

//mixing
.mixing {
  padding: 0 20px 60px !important;

  &Image {
    background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/ourCocktail/mixingImage.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    background-position: 100%;
    display: flex;
  }

  .mixingContent {
    width: auto;
    @include d-flex($jc: center);
    flex-direction: column;
    gap: 32px;
    padding: 0 0 0 80px;

    .commonHeading {
      @include d-flex($al: flex-start);
      gap: 16px;

      h2 {
        color: $color-white-0;
      }

      p {
        color: $color-white-0;
      }
    }
  }

  // responsive
  @media (max-width: 992px) {
    padding: 0 60px 60px !important;

    &Image {
      min-height: 232px;
    }

    .mixingContent {
      padding: 30px 0 0 48px;
      justify-content: flex-start;
      gap: 32px;

      .commonHeading {
        max-width: 324px;
        text-align: start;
        gap: 8px;

        h2 {
          @include font-style($fs: 3.2rem, $fw: 500, $lh: 140%);
        }

        p {
          @include font-style($fs: 1.6rem, $fw: 400, $lh: 140%);
        }
      }
    }
  }

  @media (max-width: 568px) {
    padding: 0 20px 60px !important;

    &Image {
      background-image: url("https://tharra-website-assets.s3.amazonaws.com/assets/images/ourCocktail/mixingMobileImage.webp") !important;
      background-position: bottom;
      min-height: 505px;
      padding: 52px 34px 0 40px;
    }

    .mixingContent {
      padding: 0;
    }
  }
}
